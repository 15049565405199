import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@agyt/client/shared/ui/components';
import { useTranslation } from 'react-i18next';

export function DeleteBeneficiaryDialog({
  onDelete,
}: {
  onDelete: () => void;
}) {
  const { t } = useTranslation('beneficiaries');
  return (
    <Dialog>
      <DialogTrigger asChild onClick={(e) => e.stopPropagation()}>
        <Button variant="subtleDestructive">{t('actions.delete')}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('deleteDialog.title')}</DialogTitle>
        </DialogHeader>
        <DialogDescription>{t('deleteDialog.description')}</DialogDescription>
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild onClick={(e) => e.stopPropagation()}>
            <Button type="button" variant="outline">
              {t('actions.close')}
            </Button>
          </DialogClose>
          <DialogClose asChild onClick={(e) => e.stopPropagation()}>
            <Button variant="subtleDestructive" onClick={onDelete}>
              {t('actions.delete')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteBeneficiaryDialog;
