import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { createRoute } from '@tanstack/react-router';
import { Logo } from '@agyt/client/shared/ui/icons';
import { Button } from '@agyt/client/shared/ui/components';
import { rootRoute } from './root-route';

export const welcomeRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/welcome',
  component: WelcomePage,
});

function WelcomePage() {
  const { t } = useTranslation('welcome');
  const { loginWithRedirect } = useAuth0();

  function handleLogin() {
    const params = new URLSearchParams(window.location.search);
    const returnTo = params.get('returnTo') ?? '';

    loginWithRedirect({
      appState: { returnTo },
    });
  }

  return (
    <div className="flex h-full w-full items-center justify-center bg-slate-50">
      <div className="flex h-[404px] w-[644px] flex-col items-center justify-center border border-slate-200 bg-white p-16">
        <Logo className="mb-16" />
        <h1 className="color-slate-900 mb-6 text-2xl font-medium">
          {t('title')}
        </h1>
        <p className="color-slate-500 mb-16 text-base">{t('subtitle')}</p>
        <Button onClick={handleLogin}>{t('cta')}</Button>
      </div>
    </div>
  );
}
