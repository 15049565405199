import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';

import { useHttpClient } from '@agyt/client/web/core/http';
import {
  ExportTransactionCriteriaQuery,
  SuccessApiResponse,
  TransactionCriteriaQuery,
  TransactionDetailsResponse,
  TransactionListResponse,
} from '@agyt/shared/types';

export const TRANSACTIONS_CACHE_KEY = 'transactions';

export function useFindTransactions(
  criteria?: Partial<TransactionCriteriaQuery>,
) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: [TRANSACTIONS_CACHE_KEY, criteria],
    async queryFn() {
      const res = await httpClient.get('/transactions/find', {
        params: { filter: criteria },
      });
      return res?.data as SuccessApiResponse<TransactionListResponse[]>;
    },
    staleTime: 30 * 1000,
    placeholderData: keepPreviousData,
  });
}

export function useGetTransactionDetails({ id }: { id?: string }) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: [TRANSACTIONS_CACHE_KEY, id],
    enabled: !!id,
    async queryFn() {
      const res = await httpClient.get(`/transactions/${id}`);
      return res?.data as SuccessApiResponse<TransactionDetailsResponse>;
    },
    staleTime: 30 * 1000,
    placeholderData: keepPreviousData,
  });
}

export function useGetFirstTransaction({ currency }: { currency?: string }) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: [TRANSACTIONS_CACHE_KEY, 'first', currency],
    enabled: !!currency,
    async queryFn() {
      const res = await httpClient.get(`/transactions/first`, {
        params: { filter: { currency } },
      });
      return res?.data as SuccessApiResponse<TransactionListResponse>;
    },
    staleTime: 30 * 1000,
    placeholderData: keepPreviousData,
  });
}

export function useDownloadStatement() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async (criteria: ExportTransactionCriteriaQuery) => {
      try {
        const res = await httpClient.get('/transactions/statement', {
          params: { filter: criteria },
          responseType: 'blob',
        });

        return res.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'downloadStatement',
    },
  });
}

export function useExportTransactions() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async (criteria: TransactionCriteriaQuery) => {
      try {
        const res = await httpClient.get('/transactions/export', {
          params: { filter: criteria },
          responseType: 'blob',
        });

        return res.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'exportTransactions',
    },
  });
}
