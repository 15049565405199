import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@agyt/client/shared/util/theme';

const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2 py-1 text-sm leading-5 font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-slate-200 text-slate-900',
        outline: 'text-foreground',
        lime: 'border-transparent bg-lime-200 text-slate-900',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
