import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z, ZodObject, ZodRawShape, ZodString } from 'zod';

import {
  Button,
  Checkbox,
  CountryPicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Label,
  LoadingButton,
  Separator,
} from '@agyt/client/shared/ui/components';
import { BeneficiaryRequirements } from '@agyt/client/web/data-access/api';
import { ApiFieldError, KeyValueObject } from '@agyt/shared/types';
import { countries } from '@agyt/shared/util/common';

// These are snake_cased because we get them straight from the CC requiments API
const accountDetailsOrderMap: KeyValueObject<number> = {
  iban: 1,
  acct_number: 2,
  bic_swift: 3,
  sort_code: 4,
  aba: 5,
  bsb_code: 6,
  institution_number: 7,
  bank_code: 8,
  branch_code: 9,
  clabe: 10,
  cnaps: 11,
  beneficiary_first_name: 12,
  beneficiary_last_name: 13,
  beneficiary_company_name: 14,
  beneficiary_address: 15,
  beneficiary_city: 16,
  beneficiary_postcode: 17,
  beneficiary_state_or_province: 18,
  beneficiary_country: 19,
};

export interface BankAccountDetailsFormProps {
  formData: any[];
  showSwift: boolean;
  setShowSwift: (show: boolean) => void;
  beneficiaryRequirements: BeneficiaryRequirements[];
  submit: (data: any) => void;
  back: () => void;
  errors?: ApiFieldError[];
  isValidating?: boolean;
}

export function BankAccountDetailsForm({
  formData,
  showSwift,
  setShowSwift,
  beneficiaryRequirements,
  submit,
  back,
  errors,
  isValidating,
}: BankAccountDetailsFormProps) {
  const { t } = useTranslation('beneficiaries:new');

  const priorityRequirements = useMemo(
    () => beneficiaryRequirements.filter((r) => r.payment_type === 'priority'),
    [beneficiaryRequirements],
  );

  const regularRequirements = useMemo(() => {
    const regular = beneficiaryRequirements.filter(
      (r) => r.payment_type === 'regular',
    );
    if (regular?.length) {
      const { beneficiary_entity_type, payment_type, ...rest } = regular[0];
      return rest;
    }
    return null;
  }, [beneficiaryRequirements]);

  const hasInternational = !!priorityRequirements?.length;
  const hasLocal = !!regularRequirements;
  const hasOnlyInternational = hasInternational && !hasLocal;

  const filteredPriorityRequirements = useMemo(() => {
    const values = new Map();
    priorityRequirements.forEach((r) => {
      const { payment_type, beneficiary_entity_type, ...rest } = r;
      Object.keys(r).forEach((key: string) => {
        if (key === 'payment_type' || key === 'beneficiary_entity_type') {
          return;
        }

        if (!regularRequirements) {
          values.set(key, rest[key]);
        }

        if (regularRequirements && !regularRequirements[key]) {
          values.set(key, rest[key]);
        }
      });
    });

    return values;
  }, [priorityRequirements, regularRequirements]);

  function onSubmit(data: KeyValueObject<any>) {
    let paymentType = 'regular';
    if (hasLocal && showSwift) {
      paymentType = 'priority,regular';
    }
    if (hasOnlyInternational) {
      paymentType = 'priority';
    }

    const payload = {
      ...data,
      payment_type: paymentType,
    };
    submit(payload);
  }

  const formSchema = useMemo(() => {
    if (!regularRequirements && !priorityRequirements) {
      return z.object({});
    }

    const obj: KeyValueObject<ZodString> = {};
    if (regularRequirements) {
      Object.keys(regularRequirements).forEach((key) => {
        obj[key] = z
          .string({ message: 'required' })
          .regex(new RegExp(regularRequirements[key]), { message: 'format' });
      });
    }

    if (
      (showSwift && filteredPriorityRequirements?.size > 0) ||
      hasOnlyInternational
    ) {
      for (const [key, value] of filteredPriorityRequirements.entries()) {
        obj[key] = z.string({ message: 'required' }).regex(new RegExp(value), {
          message: 'format',
        });
      }
    }
    return z.object(obj) as ZodObject<ZodRawShape>;
  }, [
    regularRequirements,
    showSwift,
    filteredPriorityRequirements,
    priorityRequirements,
    hasOnlyInternational,
  ]);

  const defaults = useMemo(() => {
    if (formData && (formData.length || Object.keys(formData).length)) {
      return formData;
    }

    if (!regularRequirements && !priorityRequirements) {
      return {};
    }

    const obj: KeyValueObject<string> = {};
    if (regularRequirements) {
      Object.keys(regularRequirements).forEach((key) => {
        obj[key] = '';
      });
    }

    if (filteredPriorityRequirements?.size > 0) {
      filteredPriorityRequirements.forEach((value, key) => {
        obj[key] = '';
      });
    }

    return obj;
  }, [
    regularRequirements,
    filteredPriorityRequirements,
    formData,
    priorityRequirements,
  ]);

  type FormSchemaType = z.infer<typeof formSchema>;
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: defaults,
  });

  useEffect(() => {
    if (errors && form) {
      errors.forEach((error) => {
        if (error.field) {
          form.setError(error.field, {
            type: 'manual',
            message: t(`bankAccountDetails.errors.${error.code}`),
          });
        }
      });
    }
  }, [errors, form, t]);

  return (
    <div className="mt-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            {hasLocal &&
              Object.keys(regularRequirements)
                .sort(
                  (a, b) =>
                    accountDetailsOrderMap[a] - accountDetailsOrderMap[b],
                )
                .map((prop) => {
                  return (
                    <FormField
                      key={prop}
                      control={form.control}
                      name={prop}
                      render={({ field }) => (
                        <FormItem className="mb-5">
                          <FormLabel className="mb-2 block text-sm font-medium text-slate-900">
                            {t(`bankAccountDetails.labels.${prop}`)}
                          </FormLabel>
                          <FormControl>
                            {prop === 'beneficiary_country' ? (
                              <CountryPicker
                                value={countries.find(
                                  (country) => country.value === field.value,
                                )}
                                onChange={(country) =>
                                  field.onChange(country?.value)
                                }
                              />
                            ) : (
                              <Input {...field}></Input>
                            )}
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  );
                })}
          </div>

          {hasInternational && !hasOnlyInternational && (
            <>
              <Separator className="mb-6" />
              <Label htmlFor="swift-switch" className="hover:cursor-pointer">
                <div className="flex w-full items-center rounded-md border border-slate-300 bg-white p-3">
                  <Checkbox
                    id="swift-switch"
                    checked={showSwift}
                    onCheckedChange={() => setShowSwift(!showSwift)}
                    className="mr-2"
                  />
                  Swift
                </div>
              </Label>
            </>
          )}
          {(showSwift || hasOnlyInternational) && (
            <div className="mt-4 flex flex-col">
              {Array.from(filteredPriorityRequirements)
                .sort((a, b) => {
                  return (
                    accountDetailsOrderMap[a[0]] - accountDetailsOrderMap[b[0]]
                  );
                })
                .map(([key, _]) => (
                  <FormField
                    key={key}
                    control={form.control}
                    name={key}
                    render={({ field }) => (
                      <FormItem className="mb-5">
                        <FormLabel className="mb-2 block text-sm font-medium text-slate-900">
                          {t(`bankAccountDetails.labels.${key}`)}
                        </FormLabel>
                        <FormControl>
                          {key === 'beneficiary_country' ? (
                            <CountryPicker
                              value={countries.find(
                                (country) => country.value === field.value,
                              )}
                              onChange={(country) =>
                                field.onChange(country?.value)
                              }
                            />
                          ) : (
                            <Input {...field}></Input>
                          )}
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
            </div>
          )}

          <div className="mt-16 flex gap-4">
            <Button
              variant="subtleDark"
              className="flex-1"
              onClick={back}
              type="button"
            >
              {t('actions.back', { ns: 'common' })}
            </Button>
            <LoadingButton
              className="flex-1"
              type="submit"
              isLoading={!!isValidating}
            >
              {t('actions.next', { ns: 'common' })}
            </LoadingButton>
          </div>
        </form>
      </Form>
    </div>
  );
}

export default BankAccountDetailsForm;
