import { useAuth0 } from '@auth0/auth0-react';
import {
  createRoute,
  Navigate,
  Outlet,
  redirect,
  useNavigate,
} from '@tanstack/react-router';

import {
  InactivityGuardProvider,
  useInactivityGuard,
} from '@agyt/client/web/core/user';
import { useEffect, useState } from 'react';
import Navigation from './navigation';
import { rootRoute } from './root-route';
import { InactivityDialog } from '@agyt/client/shared/ui/components';

const IndexPageWithProviders = () => (
  <InactivityGuardProvider>
    <IndexPage />
  </InactivityGuardProvider>
);

export const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'index',
  component: IndexPageWithProviders,
  beforeLoad: ({ context, location }) => {
    const { isLoading, isAuthenticated } = context.auth;
    if (!isLoading && !isAuthenticated) {
      throw redirect({
        to: '/welcome',
        search: {
          returnTo: location.href,
        },
      });
    }
  },
});

export function IndexPage() {
  const { isLoading, isAuthenticated, logout } = useAuth0();
  const { initiate, blockUpdate, onUserAction } = useInactivityGuard();
  const navigate = useNavigate();
  const [inactivityDialogOpen, setInactivityDialogOpen] = useState(false);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      initiate({
        onLogout: async () => {
          setInactivityDialogOpen(false);
          await logout({
            logoutParams: {
              returnTo: `${window.location.origin}/inactive?returnTo=${window.location.href}`,
            },
          });
        },
        onWarning: () => {
          if (!inactivityDialogOpen) {
            setInactivityDialogOpen(true);
            blockUpdate();
          }
        },
      });
    }
  }, [
    isLoading,
    isAuthenticated,
    initiate,
    logout,
    navigate,
    inactivityDialogOpen,
    blockUpdate,
  ]);

  if (!isLoading && !isAuthenticated) {
    return <Navigate to={'/welcome'} />;
  }

  function onDialogDismiss() {
    onUserAction();
    setInactivityDialogOpen(false);
  }

  return (
    <div className="flex h-full w-full">
      <Navigation />
      <div className="flex-1 overflow-y-auto bg-slate-50">
        <Outlet />
      </div>
      {inactivityDialogOpen && (
        <InactivityDialog open={true} dismiss={onDialogDismiss} />
      )}
    </div>
  );
}
