import { useTranslation } from 'react-i18next';
import { ComboboxItem, Combobox } from './combobox';
import { useGetPaymentPurposeCodes } from '@agyt/client/web/data-access/api';
import { SupportedCurrency } from '@agyt/shared/util/common';

export interface PurposeCode extends ComboboxItem {
  value: string;
  label: string;
}

export interface PaymentPurposeCodePickerProps {
  value?: string;
  onChange: (value: PurposeCode | undefined) => void;
  bankAccountCountry: string;
  currency: SupportedCurrency;
  entityType?: string;
}

export function PaymentPurposeCodePicker({
  value,
  onChange,
  bankAccountCountry,
  currency,
  entityType,
}: PaymentPurposeCodePickerProps) {
  const { t } = useTranslation('common');
  const { data: paymentPurposeCodes } = useGetPaymentPurposeCodes({
    bankAccountCountry,
    currency,
    entityType,
  });

  function formatPurposeCodeLabel(purposeCode: string) {
    const isSpecialCountry = ['CN', 'BH', 'AE'].includes(bankAccountCountry);
    const formatted = purposeCode
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/^\w/g, (char) => char.toUpperCase());

    return !isSpecialCountry ? formatted : formatted.toUpperCase();
  }

  const purposeCodes = paymentPurposeCodes?.data.map((code: any) => ({
    value: code.purposeCode,
    label: formatPurposeCodeLabel(code.purposeCode),
    description: ['CN', 'BH', 'AE'].includes(bankAccountCountry)
      ? t(`purposeCodeDescription.${bankAccountCountry}.${code.purposeCode}`)
      : t(`purposeCodeDescription.${code.purposeCode}`),
  }));

  return (
    <Combobox<PurposeCode>
      items={purposeCodes || []}
      emptyLabel={t('purposeCode.empty')}
      placeholder={t('purposeCode.placeholder')}
      selectedItem={purposeCodes?.find((code: any) => code.value === value)}
      onSelect={onChange}
    />
  );
}

export default PaymentPurposeCodePicker;
