import * as React from 'react';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { DayPicker, defaultLocale } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import {
  IconChevronLeft,
  IconChevronRight,
  IconCalendarDays,
} from '@agyt/client/shared/ui/icons';
import { cn } from '@agyt/client/shared/util/theme';
import { useUser } from '@agyt/client/web/core/user';
import { LocalDate } from '@agyt/shared/util/common';
import { Button, buttonVariants } from './button';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const Calendar = ({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) => {
  const { t } = useTranslation('common');
  return (
    <DayPicker
      locale={{
        localize: {
          ...defaultLocale.localize,
          day: (day) => t(`calendar.days.${day}`),
          month: (month) => t(`calendar.months.${month}`),
        },
      }}
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'relative flex flex-col sm:flex-row space-y-4 sm:space-y-0',
        month: 'space-y-4',
        nav: 'flex items-center justify-between absolute w-full z-10 px-1',
        button_previous: cn(
          buttonVariants({
            variant: 'outline',
            className:
              'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
          }),
        ),
        button_next: cn(
          buttonVariants({
            variant: 'outline',
            className:
              'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
          }),
        ),
        selected: 'bg-slate-900 text-slate-100 rounded-md hover:bg-slate-800',
        month_caption: 'flex justify-center items-center h-7',
        caption_label: 'text-sm font-medium',
        month_grid: 'border-collapse space-y-1',
        weekdays: 'flex',
        weekday: 'text-muted-foreground w-9 font-normal text-xs',
        weeks: '',
        week: 'flex mt-2',
        day: 'p-0',
        outside: 'bg-accent/40',
        range_middle: 'bg-accent last:rounded-e-md first:rounded-s-md',
        range_start: 'bg-accent rounded-s-md',
        range_end: 'bg-accent rounded-e-md',
        ...classNames,
      }}
      components={{
        DayButton({ day, modifiers, className, ...buttonProps }) {
          return (
            <Button
              variant={'ghost'}
              className={cn(
                className,
                'h-9 w-9 p-0 font-normal',
                modifiers?.today && 'bg-accent text-accent-foreground',
                modifiers?.selected &&
                  'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
                modifiers?.outside &&
                  'text-muted-foreground pointer-events-none opacity-50',
                modifiers?.disabled && 'text-muted-foreground opacity-50',
                modifiers?.hidden && 'invisible',
                modifiers.range_middle &&
                  'bg-accent text-accent-foreground hover:bg-accent hover:text-accent-foreground rounded-none first:rounded-s-md last:rounded-e-md',
                modifiers.outside &&
                  modifiers.selected &&
                  'bg-accent/40 text-muted-foreground',
              )}
              {...buttonProps}
              aria-selected={modifiers.selected || buttonProps['aria-selected']}
              aria-disabled={modifiers.disabled || buttonProps['aria-disabled']}
              aria-hidden={modifiers.hidden || buttonProps['aria-hidden']}
            />
          );
        },
        Chevron: (props) =>
          props.orientation === 'left' ? (
            <IconChevronLeft className="h-4 w-4" />
          ) : (
            <IconChevronRight className="h-4 w-4" />
          ),
      }}
      {...props}
    />
  );
};

Calendar.displayName = 'Calendar';

interface DatePickerProps {
  date?: string;
  onSelect?: (date: string | null) => void;
  disabled?: CalendarProps['disabled'];
  disabledButton?: boolean;
  className?: string;
  placeholder?: string;
}

export function DatePicker({
  disabled,
  disabledButton,
  date,
  onSelect,
  className,
  placeholder,
}: DatePickerProps) {
  const { t } = useTranslation('common');
  const { locale, firstDayOfWeek, tz } = useUser();
  const [open, setOpen] = React.useState(false);

  const internalDate = useMemo(() => {
    if (!date) {
      return new Date();
    }
    return new Date(date);
  }, [date]);

  function handleSelect(selectedDate?: Date) {
    if (!selectedDate) {
      return;
    }

    if (onSelect) {
      onSelect(DateTime.fromJSDate(selectedDate).toISODate());
      setOpen(false);
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          disabled={disabledButton}
          variant="outline"
          className={cn(
            'w-full justify-start text-left font-normal',
            !date && 'text-muted-foreground',
            className,
          )}
        >
          <IconCalendarDays className="mr-2 h-4 w-4" />
          {internalDate ? (
            new LocalDate({
              timestamp: DateTime.fromJSDate(internalDate).toISODate() || '',
              locale,
              tz,
            }).format(DateTime.DATE_SHORT)
          ) : (
            <span>{placeholder || t('datePicker.pickDate')}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={internalDate}
          onSelect={handleSelect}
          disabled={disabled}
          weekStartsOn={firstDayOfWeek}
          autoFocus
        />
      </PopoverContent>
    </Popover>
  );
}

export default DatePicker;
