export const countries = [
  { value: 'AF', label: '🇦🇫 Afghanistan', shortLabel: 'Afghanistan' },
  { value: 'AL', label: '🇦🇱 Albania', shortLabel: 'Albania' },
  { value: 'DZ', label: '🇩🇿 Algeria', shortLabel: 'Algeria' },
  { value: 'AD', label: '🇦🇩 Andorra', shortLabel: 'Andorra' },
  { value: 'AO', label: '🇦🇴 Angola', shortLabel: 'Angola' },
  {
    value: 'AG',
    label: '🇦🇬 Antigua and Barbuda',
    shortLabel: 'Antigua and Barbuda',
  },
  { value: 'AR', label: '🇦🇷 Argentina', shortLabel: 'Argentina' },
  { value: 'AM', label: '🇦🇲 Armenia', shortLabel: 'Armenia' },
  { value: 'AU', label: '🇦🇺 Australia', shortLabel: 'Australia' },
  { value: 'AT', label: '🇦🇹 Austria', shortLabel: 'Austria' },
  { value: 'AZ', label: '🇦🇿 Azerbaijan', shortLabel: 'Azerbaijan' },
  { value: 'BS', label: '🇧🇸 Bahamas', shortLabel: 'Bahamas' },
  { value: 'BH', label: '🇧🇭 Bahrain', shortLabel: 'Bahrain' },
  { value: 'BD', label: '🇧🇩 Bangladesh', shortLabel: 'Bangladesh' },
  { value: 'BB', label: '🇧🇧 Barbados', shortLabel: 'Barbados' },
  { value: 'BY', label: '🇧🇾 Belarus', shortLabel: 'Belarus' },
  { value: 'BE', label: '🇧🇪 Belgium', shortLabel: 'Belgium' },
  { value: 'BZ', label: '🇧🇿 Belize', shortLabel: 'Belize' },
  { value: 'BJ', label: '🇧🇯 Benin', shortLabel: 'Benin' },
  { value: 'BT', label: '🇧🇹 Bhutan', shortLabel: 'Bhutan' },
  { value: 'BO', label: '🇧🇴 Bolivia', shortLabel: 'Bolivia' },
  {
    value: 'BA',
    label: '🇧🇦 Bosnia and Herzegovina',
    shortLabel: 'Bosnia and Herzegovina',
  },
  { value: 'BW', label: '🇧🇼 Botswana', shortLabel: 'Botswana' },
  { value: 'BR', label: '🇧🇷 Brazil', shortLabel: 'Brazil' },
  { value: 'BN', label: '🇧🇳 Brunei', shortLabel: 'Brunei' },
  { value: 'BG', label: '🇧🇬 Bulgaria', shortLabel: 'Bulgaria' },
  { value: 'BF', label: '🇧🇫 Burkina Faso', shortLabel: 'Burkina Faso' },
  { value: 'BI', label: '🇧🇮 Burundi', shortLabel: 'Burundi' },
  { value: 'CV', label: '🇨🇻 Cabo Verde', shortLabel: 'Cabo Verde' },
  { value: 'KH', label: '🇰🇭 Cambodia', shortLabel: 'Cambodia' },
  { value: 'CM', label: '🇨🇲 Cameroon', shortLabel: 'Cameroon' },
  { value: 'CA', label: '🇨🇦 Canada', shortLabel: 'Canada' },
  {
    value: 'CF',
    label: '🇨🇫 Central African Republic',
    shortLabel: 'Central African Republic',
  },
  { value: 'TD', label: '🇹🇩 Chad', shortLabel: 'Chad' },
  { value: 'CL', label: '🇨🇱 Chile', shortLabel: 'Chile' },
  { value: 'CN', label: '🇨🇳 China', shortLabel: 'China' },
  { value: 'CO', label: '🇨🇴 Colombia', shortLabel: 'Colombia' },
  { value: 'KM', label: '🇰🇲 Comoros', shortLabel: 'Comoros' },
  { value: 'CD', label: '🇨🇩 Congo (DRC)', shortLabel: 'Congo (DRC)' },
  { value: 'CG', label: '🇨🇬 Congo (Republic)', shortLabel: 'Congo (Republic)' },
  { value: 'CR', label: '🇨🇷 Costa Rica', shortLabel: 'Costa Rica' },
  { value: 'CI', label: "🇨🇮 Côte d'Ivoire", shortLabel: "Côte d'Ivoire" },
  { value: 'HR', label: '🇭🇷 Croatia', shortLabel: 'Croatia' },
  { value: 'CU', label: '🇨🇺 Cuba', shortLabel: 'Cuba' },
  { value: 'CY', label: '🇨🇾 Cyprus', shortLabel: 'Cyprus' },
  { value: 'CZ', label: '🇨🇿 Czech Republic', shortLabel: 'Czech Republic' },
  { value: 'DK', label: '🇩🇰 Denmark', shortLabel: 'Denmark' },
  { value: 'DJ', label: '🇩🇯 Djibouti', shortLabel: 'Djibouti' },
  { value: 'DM', label: '🇩🇲 Dominica', shortLabel: 'Dominica' },
  {
    value: 'DO',
    label: '🇩🇴 Dominican Republic',
    shortLabel: 'Dominican Republic',
  },
  { value: 'EC', label: '🇪🇨 Ecuador', shortLabel: 'Ecuador' },
  { value: 'EG', label: '🇪🇬 Egypt', shortLabel: 'Egypt' },
  { value: 'SV', label: '🇸🇻 El Salvador', shortLabel: 'El Salvador' },
  {
    value: 'GQ',
    label: '🇬🇶 Equatorial Guinea',
    shortLabel: 'Equatorial Guinea',
  },
  { value: 'ER', label: '🇪🇷 Eritrea', shortLabel: 'Eritrea' },
  { value: 'EE', label: '🇪🇪 Estonia', shortLabel: 'Estonia' },
  { value: 'SZ', label: '🇸🇿 Eswatini', shortLabel: 'Eswatini' },
  { value: 'ET', label: '🇪🇹 Ethiopia', shortLabel: 'Ethiopia' },
  { value: 'FJ', label: '🇫🇯 Fiji', shortLabel: 'Fiji' },
  { value: 'FI', label: '🇫🇮 Finland', shortLabel: 'Finland' },
  { value: 'FR', label: '🇫🇷 France', shortLabel: 'France' },
  { value: 'GA', label: '🇬🇦 Gabon', shortLabel: 'Gabon' },
  { value: 'GM', label: '🇬🇲 Gambia', shortLabel: 'Gambia' },
  { value: 'GE', label: '🇬🇪 Georgia', shortLabel: 'Georgia' },
  { value: 'DE', label: '🇩🇪 Germany', shortLabel: 'Germany' },
  { value: 'GH', label: '🇬🇭 Ghana', shortLabel: 'Ghana' },
  { value: 'GR', label: '🇬🇷 Greece', shortLabel: 'Greece' },
  { value: 'GD', label: '🇬🇩 Grenada', shortLabel: 'Grenada' },
  { value: 'GT', label: '🇬🇹 Guatemala', shortLabel: 'Guatemala' },
  { value: 'GN', label: '🇬🇳 Guinea', shortLabel: 'Guinea' },
  { value: 'GW', label: '🇬🇼 Guinea-Bissau', shortLabel: 'Guinea-Bissau' },
  { value: 'GY', label: '🇬🇾 Guyana', shortLabel: 'Guyana' },
  { value: 'HT', label: '🇭🇹 Haiti', shortLabel: 'Haiti' },
  { value: 'HN', label: '🇭🇳 Honduras', shortLabel: 'Honduras' },
  { value: 'HU', label: '🇭🇺 Hungary', shortLabel: 'Hungary' },
  { value: 'IS', label: '🇮🇸 Iceland', shortLabel: 'Iceland' },
  { value: 'IN', label: '🇮🇳 India', shortLabel: 'India' },
  { value: 'ID', label: '🇮🇩 Indonesia', shortLabel: 'Indonesia' },
  { value: 'IR', label: '🇮🇷 Iran', shortLabel: 'Iran' },
  { value: 'IQ', label: '🇮🇶 Iraq', shortLabel: 'Iraq' },
  { value: 'IE', label: '🇮🇪 Ireland', shortLabel: 'Ireland' },
  { value: 'IL', label: '🇮🇱 Israel', shortLabel: 'Israel' },
  { value: 'IT', label: '🇮🇹 Italy', shortLabel: 'Italy' },
  { value: 'JM', label: '🇯🇲 Jamaica', shortLabel: 'Jamaica' },
  { value: 'JP', label: '🇯🇵 Japan', shortLabel: 'Japan' },
  { value: 'JO', label: '🇯🇴 Jordan', shortLabel: 'Jordan' },
  { value: 'KZ', label: '🇰🇿 Kazakhstan', shortLabel: 'Kazakhstan' },
  { value: 'KE', label: '🇰🇪 Kenya', shortLabel: 'Kenya' },
  { value: 'KI', label: '🇰🇮 Kiribati', shortLabel: 'Kiribati' },
  { value: 'KP', label: '🇰🇵 North Korea', shortLabel: 'North Korea' },
  { value: 'KR', label: '🇰🇷 South Korea', shortLabel: 'South Korea' },
  { value: 'KW', label: '🇰🇼 Kuwait', shortLabel: 'Kuwait' },
  { value: 'KG', label: '🇰🇬 Kyrgyzstan', shortLabel: 'Kyrgyzstan' },
  { value: 'LA', label: '🇱🇦 Laos', shortLabel: 'Laos' },
  { value: 'LV', label: '🇱🇻 Latvia', shortLabel: 'Latvia' },
  { value: 'LB', label: '🇱🇧 Lebanon', shortLabel: 'Lebanon' },
  { value: 'LS', label: '🇱🇸 Lesotho', shortLabel: 'Lesotho' },
  { value: 'LR', label: '🇱🇷 Liberia', shortLabel: 'Liberia' },
  { value: 'LY', label: '🇱🇾 Libya', shortLabel: 'Libya' },
  { value: 'LI', label: '🇱🇮 Liechtenstein', shortLabel: 'Liechtenstein' },
  { value: 'LT', label: '🇱🇹 Lithuania', shortLabel: 'Lithuania' },
  { value: 'LU', label: '🇱🇺 Luxembourg', shortLabel: 'Luxembourg' },
  { value: 'MG', label: '🇲🇬 Madagascar', shortLabel: 'Madagascar' },
  { value: 'MW', label: '🇲🇼 Malawi', shortLabel: 'Malawi' },
  { value: 'MY', label: '🇲🇾 Malaysia', shortLabel: 'Malaysia' },
  { value: 'MV', label: '🇲🇻 Maldives', shortLabel: 'Maldives' },
  { value: 'ML', label: '🇲🇱 Mali', shortLabel: 'Mali' },
  { value: 'MT', label: '🇲🇹 Malta', shortLabel: 'Malta' },
  { value: 'MH', label: '🇲🇭 Marshall Islands', shortLabel: 'Marshall Islands' },
  { value: 'MR', label: '🇲🇷 Mauritania', shortLabel: 'Mauritania' },
  { value: 'MU', label: '🇲🇺 Mauritius', shortLabel: 'Mauritius' },
  { value: 'MX', label: '🇲🇽 Mexico', shortLabel: 'Mexico' },
  { value: 'FM', label: '🇫🇲 Micronesia', shortLabel: 'Micronesia' },
  { value: 'MD', label: '🇲🇩 Moldova', shortLabel: 'Moldova' },
  { value: 'MC', label: '🇲🇨 Monaco', shortLabel: 'Monaco' },
  { value: 'MN', label: '🇲🇳 Mongolia', shortLabel: 'Mongolia' },
  { value: 'ME', label: '🇲🇪 Montenegro', shortLabel: 'Montenegro' },
  { value: 'MA', label: '🇲🇦 Morocco', shortLabel: 'Morocco' },
  { value: 'MZ', label: '🇲🇿 Mozambique', shortLabel: 'Mozambique' },
  { value: 'MM', label: '🇲🇲 Myanmar', shortLabel: 'Myanmar' },
  { value: 'NA', label: '🇳🇦 Namibia', shortLabel: 'Namibia' },
  { value: 'NR', label: '🇳🇷 Nauru', shortLabel: 'Nauru' },
  { value: 'NP', label: '🇳🇵 Nepal', shortLabel: 'Nepal' },
  { value: 'NL', label: '🇳🇱 Netherlands', shortLabel: 'Netherlands' },
  { value: 'NZ', label: '🇳🇿 New Zealand', shortLabel: 'New Zealand' },
  { value: 'NI', label: '🇳🇮 Nicaragua', shortLabel: 'Nicaragua' },
  { value: 'NE', label: '🇳🇪 Niger', shortLabel: 'Niger' },
  { value: 'NG', label: '🇳🇬 Nigeria', shortLabel: 'Nigeria' },
  { value: 'MK', label: '🇲🇰 North Macedonia', shortLabel: 'North Macedonia' },
  { value: 'NO', label: '🇳🇴 Norway', shortLabel: 'Norway' },
  { value: 'OM', label: '🇴🇲 Oman', shortLabel: 'Oman' },
  { value: 'PK', label: '🇵🇰 Pakistan', shortLabel: 'Pakistan' },
  { value: 'PW', label: '🇵🇼 Palau', shortLabel: 'Palau' },
  { value: 'PA', label: '🇵🇦 Panama', shortLabel: 'Panama' },
  { value: 'PG', label: '🇵🇬 Papua New Guinea', shortLabel: 'Papua New Guinea' },
  { value: 'PY', label: '🇵🇾 Paraguay', shortLabel: 'Paraguay' },
  { value: 'PE', label: '🇵🇪 Peru', shortLabel: 'Peru' },
  { value: 'PH', label: '🇵🇭 Philippines', shortLabel: 'Philippines' },
  { value: 'PL', label: '🇵🇱 Poland', shortLabel: 'Poland' },
  { value: 'PT', label: '🇵🇹 Portugal', shortLabel: 'Portugal' },
  { value: 'QA', label: '🇶🇦 Qatar', shortLabel: 'Qatar' },
  { value: 'RO', label: '🇷🇴 Romania', shortLabel: 'Romania' },
  { value: 'RU', label: '🇷🇺 Russia', shortLabel: 'Russia' },
  { value: 'RW', label: '🇷🇼 Rwanda', shortLabel: 'Rwanda' },
  {
    value: 'KN',
    label: '🇰🇳 Saint Kitts and Nevis',
    shortLabel: 'Saint Kitts and Nevis',
  },
  { value: 'LC', label: '🇱🇨 Saint Lucia', shortLabel: 'Saint Lucia' },
  {
    value: 'VC',
    label: '🇻🇨 Saint Vincent and the Grenadines',
    shortLabel: 'Saint Vincent and the Grenadines',
  },
  { value: 'WS', label: '🇼🇸 Samoa', shortLabel: 'Samoa' },
  { value: 'SM', label: '🇸🇲 San Marino', shortLabel: 'San Marino' },
  {
    value: 'ST',
    label: '🇸🇹 Sao Tome and Principe',
    shortLabel: 'Sao Tome and Principe',
  },
  { value: 'SA', label: '🇸🇦 Saudi Arabia', shortLabel: 'Saudi Arabia' },
  { value: 'SN', label: '🇸🇳 Senegal', shortLabel: 'Senegal' },
  { value: 'RS', label: '🇷🇸 Serbia', shortLabel: 'Serbia' },
  { value: 'SC', label: '🇸🇨 Seychelles', shortLabel: 'Seychelles' },
  { value: 'SL', label: '🇸🇱 Sierra Leone', shortLabel: 'Sierra Leone' },
  { value: 'SG', label: '🇸🇬 Singapore', shortLabel: 'Singapore' },
  { value: 'SK', label: '🇸🇰 Slovakia', shortLabel: 'Slovakia' },
  { value: 'SI', label: '🇸🇮 Slovenia', shortLabel: 'Slovenia' },
  { value: 'SB', label: '🇸🇧 Solomon Islands', shortLabel: 'Solomon Islands' },
  { value: 'SO', label: '🇸🇴 Somalia', shortLabel: 'Somalia' },
  { value: 'ZA', label: '🇿🇦 South Africa', shortLabel: 'South Africa' },
  { value: 'SS', label: '🇸🇸 South Sudan', shortLabel: 'South Sudan' },
  { value: 'ES', label: '🇪🇸 Spain', shortLabel: 'Spain' },
  { value: 'LK', label: '🇱🇰 Sri Lanka', shortLabel: 'Sri Lanka' },
  { value: 'SD', label: '🇸🇩 Sudan', shortLabel: 'Sudan' },
  { value: 'SR', label: '🇸🇷 Suriname', shortLabel: 'Suriname' },
  { value: 'SE', label: '🇸🇪 Sweden', shortLabel: 'Sweden' },
  { value: 'CH', label: '🇨🇭 Switzerland', shortLabel: 'Switzerland' },
  { value: 'SY', label: '🇸🇾 Syria', shortLabel: 'Syria' },
  { value: 'TW', label: '🇹🇼 Taiwan', shortLabel: 'Taiwan' },
  { value: 'TJ', label: '🇹🇯 Tajikistan', shortLabel: 'Tajikistan' },
  { value: 'TZ', label: '🇹🇿 Tanzania', shortLabel: 'Tanzania' },
  { value: 'TH', label: '🇹🇭 Thailand', shortLabel: 'Thailand' },
  { value: 'TL', label: '🇹🇱 Timor-Leste', shortLabel: 'Timor-Leste' },
  { value: 'TG', label: '🇹🇬 Togo', shortLabel: 'Togo' },
  { value: 'TO', label: '🇹🇴 Tonga', shortLabel: 'Tonga' },
  {
    value: 'TT',
    label: '🇹🇹 Trinidad and Tobago',
    shortLabel: 'Trinidad and Tobago',
  },
  { value: 'TN', label: '🇹🇳 Tunisia', shortLabel: 'Tunisia' },
  { value: 'TR', label: '🇹🇷 Turkey', shortLabel: 'Turkey' },
  { value: 'TM', label: '🇹🇲 Turkmenistan', shortLabel: 'Turkmenistan' },
  { value: 'TV', label: '🇹🇻 Tuvalu', shortLabel: 'Tuvalu' },
  { value: 'UG', label: '🇺🇬 Uganda', shortLabel: 'Uganda' },
  { value: 'UA', label: '🇺🇦 Ukraine', shortLabel: 'Ukraine' },
  {
    value: 'AE',
    label: '🇦🇪 United Arab Emirates',
    shortLabel: 'United Arab Emirates',
  },
  { value: 'GB', label: '🇬🇧 United Kingdom', shortLabel: 'United Kingdom' },
  { value: 'US', label: '🇺🇸 United States', shortLabel: 'United States' },
  { value: 'UY', label: '🇺🇾 Uruguay', shortLabel: 'Uruguay' },
  { value: 'UZ', label: '🇺🇿 Uzbekistan', shortLabel: 'Uzbekistan' },
  { value: 'VU', label: '🇻🇺 Vanuatu', shortLabel: 'Vanuatu' },
  { value: 'VE', label: '🇻🇪 Venezuela', shortLabel: 'Venezuela' },
  { value: 'VN', label: '🇻🇳 Vietnam', shortLabel: 'Vietnam' },
  { value: 'YE', label: '🇾🇪 Yemen', shortLabel: 'Yemen' },
  { value: 'ZM', label: '🇿🇲 Zambia', shortLabel: 'Zambia' },
  { value: 'ZW', label: '🇿🇼 Zimbabwe', shortLabel: 'Zimbabwe' },
];

export function getCountryNameFromCode(code: string): string {
  const country = countries.find((c) => c.value === code);
  return country ? country.shortLabel : '';
}
