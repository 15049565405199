import { useAuth0 } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from '@tanstack/react-router';
import { useMemo } from 'react';

import { router } from '@agyt/client/web/core/router';

export function App() {
  const queryClient = useMemo(() => new QueryClient(), []);
  const { isLoading, isAuthenticated } = useAuth0();
  const auth = { isLoading, isAuthenticated };

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} context={{ auth }} />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
