import { createRoute } from '@tanstack/react-router';
import { indexRoute } from '@agyt/client/web/feature/common';
import CreatePaymentPage from './create-payment-page';
import TransactionDetailsPage from './transaction-details-page';
import TransactionsPage from './transactions-page';
import AddTransactionPage from './add-transaction-page';
import { TransactionCriteriaQuery } from '@agyt/shared/types';
import StatementsPage from './statements-page';

export const payRoot = createRoute({
  getParentRoute: () => indexRoute,
  validateSearch: (
    search: Record<string, unknown>,
  ): { beneficiaryId?: string } =>
    search.beneficiaryId
      ? {
          beneficiaryId: String(search.beneficiaryId),
        }
      : {},
  path: '/pay',
});

const fxRoot = createRoute({
  getParentRoute: () => indexRoute,
  path: '/fx',
});

const payIndexRoute = createRoute({
  getParentRoute: () => payRoot,
  path: '/',
  component: CreatePaymentPage,
});

export const transactionRoot = createRoute({
  getParentRoute: () => indexRoute,
  path: '/transactions',
  validateSearch: () => ({}) as Partial<TransactionCriteriaQuery>,
});

const transactionsIndexRoute = createRoute({
  getParentRoute: () => transactionRoot,
  path: '/',
  component: TransactionsPage,
});

const addTransactionRoute = createRoute({
  getParentRoute: () => transactionRoot,
  path: '/new',
  component: AddTransactionPage,
});

const transactionStatementsRoute = createRoute({
  getParentRoute: () => transactionRoot,
  path: '/statements/$currency',
  component: StatementsPage,
});

const transactionDetailsRoute = createRoute({
  getParentRoute: () => transactionRoot,
  path: '/$id',
  component: TransactionDetailsPage,
});

export const transactionsRoutes = transactionRoot.addChildren([
  transactionsIndexRoute,
  addTransactionRoute,
  transactionDetailsRoute,
  transactionStatementsRoute,
]);

export const payRoutes = payRoot.addChildren([payIndexRoute]);
