import React from 'react';
import { cn } from '@agyt/client/shared/util/theme';

export interface PageWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export function PageWrapper({ children, className }: PageWrapperProps) {
  return (
    <main className={`bg-slate-50 p-10 ${cn(className)}`}>{children}</main>
  );
}

export default PageWrapper;
