import { useTranslation } from 'react-i18next';
import { Combobox, ComboboxItem } from './combobox';

export interface ChargeTypePickerProps {
  value?: string;
  onChange: (value: ComboboxItem | undefined) => void;
}

const chargeTypes = [
  { value: 'ours', label: 'OURS' },
  { value: 'shared', label: 'SHARED' },
];

export function ChargeTypePicker({ value, onChange }: ChargeTypePickerProps) {
  const { t } = useTranslation('common');

  return (
    <Combobox<ComboboxItem>
      items={chargeTypes}
      emptyLabel={t('chargeType.empty')}
      placeholder={t('chargeType.placeholder')}
      selectedItem={chargeTypes.find((type) => type.value === value)}
      onSelect={onChange}
      disableSearch
    />
  );
}

export default ChargeTypePicker;
