import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Combobox,
  ComboboxItem,
  Skeleton,
} from '@agyt/client/shared/ui/components';
import { useFindBeneficiaries } from '@agyt/client/web/data-access/api';

export interface BeneficiaryItem extends ComboboxItem {
  id: string;
}

export interface BeneficiaryPickerProps {
  value?: BeneficiaryItem;
  onChange: (value: BeneficiaryItem | undefined) => void;
  prefillId?: string;
}

export function BeneficiaryPicker({
  prefillId,
  value,
  onChange,
}: BeneficiaryPickerProps) {
  const { t } = useTranslation('common');
  const { data, isFetching } = useFindBeneficiaries();
  const [selectedItem, setSelectedItem] = useState<
    BeneficiaryItem | undefined
  >();

  const beneficiaries = useMemo(() => {
    if (!data?.data) {
      return [];
    }

    const { data: beneficiaryGroups } = data;
    return beneficiaryGroups.map((beneficiaryGroup) => ({
      label: beneficiaryGroup.name,
      value: beneficiaryGroup.name,
      id: beneficiaryGroup.id,
    }));
  }, [data]);

  useEffect(() => {
    if (!selectedItem) {
      const prefillItem = prefillId
        ? beneficiaries.find((beneficiary) => beneficiary.id === prefillId)
        : value;
      if (prefillItem) {
        onChange(prefillItem);
      }
    }
  }, [prefillId, value, beneficiaries, selectedItem, onChange]);

  return isFetching ? (
    <Skeleton className="mb-4 h-10 w-52 rounded-lg" />
  ) : (
    <Combobox<BeneficiaryItem>
      items={beneficiaries}
      selectedItem={value}
      onSelect={(item) => {
        setSelectedItem(item);
        onChange(item);
      }}
      emptyLabel={t('beneficiaries.emptyLabel')}
      placeholder={t('beneficiaries.placeholder')}
    />
  );
}

export default BeneficiaryPicker;
