import { Auth0Provider } from '@auth0/auth0-react';
import i18n from 'i18next';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';

import { HttpClientProvider } from '@agyt/client/web/core/http';
import App from './app/app';
// eslint-disable-next-line @nx/enforce-module-boundaries
import en from '../../../libs/shared/assets/i18n/en.json';
// eslint-disable-next-line @nx/enforce-module-boundaries
import de from '../../../libs/shared/assets/i18n/de.json';
// eslint-disable-next-line @nx/enforce-module-boundaries
import it from '../../../libs/shared/assets/i18n/it.json';
// eslint-disable-next-line @nx/enforce-module-boundaries
import es from '../../../libs/shared/assets/i18n/es.json';
import { Toaster } from '@agyt/client/shared/ui/components';
import { UserProvider } from '@agyt/client/web/core/user';

void i18n.use(initReactI18next).init({
  resources: {
    en,
    de,
    it,
    es,
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const domain = process.env.NX_PUBLIC_AUTH0_DOMAIN || '';
const clientId = process.env.NX_PUBLIC_AUTH0_CLIENT_ID || '';
const audience = process.env.NX_PUBLIC_AUTH0_AUDIENCE || '';

root.render(
  <StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
    >
      <HttpClientProvider>
        <UserProvider>
          <App />
          <Toaster />
        </UserProvider>
      </HttpClientProvider>
    </Auth0Provider>
  </StrictMode>,
);
