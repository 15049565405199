import { createRoute, Link, Outlet, useNavigate } from '@tanstack/react-router';
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Badge,
  Button,
  DataTable,
  InputWithIcon,
  PageWrapper,
  Skeleton,
  toast,
} from '@agyt/client/shared/ui/components';
import { IconPlusCircle, IconSearch } from '@agyt/client/shared/ui/icons';
import {
  useDeleteBeneficiary,
  useGetAllBeneficiaries,
} from '@agyt/client/web/data-access/api';
import { indexRoute } from '@agyt/client/web/feature/common';
import { BeneficiaryGroupResponse } from '@agyt/shared/types';
import { AddBeneficiaryPage } from './add-beneficiary-page';
import BeneficiaryDetailsPage from './beneficiary-details-page';
import DeleteBeneficiaryDialog from './delete-beneficiary-dialog';

//   TODO replace with regular input, debounce is not needed since it's all client side
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 0,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <InputWithIcon
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    >
      <IconSearch className="text-slate-500" />
    </InputWithIcon>
  );
}

function EmptyListView() {
  const { t } = useTranslation('beneficiaries');
  return (
    <section className="flex h-[436px] w-full flex-col items-center justify-center rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex max-w-min flex-col items-center justify-center">
        <h2 className="text-nowrap text-xl font-medium text-slate-900">
          {t('empty.title')}
        </h2>
        <p className="mt-2.5 text-center leading-7 text-slate-500">
          {t('empty.subtitle')}
        </p>
        <Link to="/beneficiaries/new">
          <Button variant="subtle" className="mt-8">
            <IconPlusCircle />
            <span className="ml-2">{t('actions.add')}</span>
          </Button>
        </Link>
      </div>
    </section>
  );
}

const beneficiaryRoot = createRoute({
  getParentRoute: () => indexRoute,
  path: '/beneficiaries',
});

const beneficiariesIndexRoute = createRoute({
  getParentRoute: () => beneficiaryRoot,
  path: '/',
  component: BeneficiariesPage,
});

export const addBeneficiaryRoute = createRoute({
  getParentRoute: () => beneficiaryRoot,
  path: '/new',
  validateSearch: (search: Record<string, unknown>): { returnTo?: string } =>
    search.returnTo
      ? {
          returnTo: String(search.returnTo),
        }
      : {},
  component: AddBeneficiaryPage,
});

const beneficiaryDetailsRoute = createRoute({
  getParentRoute: () => beneficiaryRoot,
  path: '/$id',
  component: BeneficiaryDetailsPage,
});

export const beneficiariesRoutes = beneficiaryRoot.addChildren([
  beneficiariesIndexRoute,
  addBeneficiaryRoute,
  beneficiaryDetailsRoute,
]);

export function BeneficiariesPage() {
  const { t } = useTranslation('beneficiaries');
  const navigate = useNavigate({ from: '/beneficiaries/$id' });
  const { data: beneficiariesData, isFetching } = useGetAllBeneficiaries();
  const deleteBeneficiary = useDeleteBeneficiary();
  const [globalFilter, setGlobalFilter] = React.useState('');

  const beneficiaries = React.useMemo<BeneficiaryGroupResponse[]>(() => {
    if (!beneficiariesData?.data?.length) {
      return [];
    }
    return beneficiariesData.data;
  }, [beneficiariesData]);

  function onDeleteBeneficiary(id: string) {
    return async () => {
      try {
        await deleteBeneficiary.mutateAsync({ id });
      } catch (err: any) {
        toast.error(t('errors.delete_failed'));
      }
    };
  }

  const columns: ColumnDef<BeneficiaryGroupResponse>[] = [
    {
      accessorKey: 'name',
      header: t('table.columns.beneficiary'),
      enableGlobalFilter: true,
    },
    {
      id: 'actions',
      header: t('table.columns.actions'),
      cell: ({ row: { original } }) => (
        <div className="flex items-center gap-2">
          <Link
            to="/pay"
            search={{ beneficiaryId: original.id }}
            onClick={(e) => e.stopPropagation()}
          >
            <Button variant="default">{t('actions.pay')}</Button>
          </Link>
          <DeleteBeneficiaryDialog
            onDelete={onDeleteBeneficiary(original.id)}
          />
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data: beneficiaries,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFacetedRowModel: getFacetedRowModel(),
    state: {
      globalFilter,
    },
  });

  if (!beneficiaries?.length && isFetching) {
    return (
      <PageWrapper className="flex flex-col">
        <Skeleton className="mb-16 h-8 w-40 rounded-full" />
        <Skeleton className="mb-16 h-10 w-[400px] rounded-full" />

        <div className="flex flex-col gap-10">
          <Skeleton className="h-10 w-[500px] rounded-full" />
          <Skeleton className="h-10 w-[500px] rounded-full" />
          <Skeleton className="h-10 w-[500px] rounded-full" />
          <Skeleton className="h-10 w-[500px] rounded-full" />
          <Skeleton className="h-10 w-[500px] rounded-full" />
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper className="flex flex-col">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">{t('title')}</h1>
          <span>
            {!!beneficiaries?.length && (
              <Badge className="h-6 rounded-sm py-0.5">
                {beneficiaries.length}
              </Badge>
            )}
          </span>
        </div>
        <Link to="/beneficiaries/new">
          <Button>
            <IconPlusCircle />
            <span className="ml-2">{t('actions.add')}</span>
          </Button>
        </Link>
      </header>
      <div className="mt-10">
        {!beneficiaries?.length ? (
          <EmptyListView />
        ) : (
          <>
            <section className="grid grid-cols-6 items-end gap-4">
              <div className="col-span-2">
                <DebouncedInput
                  value={globalFilter ?? ''}
                  onChange={(value) => setGlobalFilter(String(value))}
                  placeholder={t('actions.search', { ns: 'common' })}
                >
                  <IconSearch className="text-slate-500" />
                </DebouncedInput>
              </div>
            </section>
            <DataTable
              table={table}
              onRowClick={({ id }) => {
                navigate({
                  to: '/beneficiaries/$id',
                  params: { id },
                });
              }}
              cellClassName="w-full"
            />
          </>
        )}
      </div>
      <Outlet />
    </PageWrapper>
  );
}

export default BeneficiariesPage;
