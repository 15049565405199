export type LocalNumberOptions = {
  value: number;
  locale: string;
};

export enum NumberStyle {
  DECIMAL = 'decimal',
  PERCENT = 'percent',
  UNIT = 'unit',
}

export class LocalNumber {
  private readonly value: number;
  private locale: string;

  constructor({ value, locale }: LocalNumberOptions) {
    try {
      this.value = value;

      Intl.getCanonicalLocales(locale);
      this.locale = locale;
    } catch (err: unknown) {
      if (err instanceof RangeError) {
        throw new Error(err.message);
      }
      throw err;
    }
  }

  format(options: Intl.NumberFormatOptions = { style: NumberStyle.DECIMAL }) {
    return new Intl.NumberFormat(this.locale, options).format(this.value);
  }

  formatAsRate() {
    return this.format({
      style: NumberStyle.DECIMAL,
      minimumFractionDigits: 4,
    });
  }
}
