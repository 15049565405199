import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  DetailsCard,
  PageWrapper,
  Skeleton,
  toast,
} from '@agyt/client/shared/ui/components';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useGetAccountDetails } from '@agyt/client/web/data-access/api';
import { accountDetailsRoot } from './router';
import { useMemo } from 'react';
import { getCountryNameFromCode } from '@agyt/shared/util/common';

const detailsOrderMap = {
  accountHolderName: 0,
  accountNumber: 1,
  routingCode: 2,
  bankName: 3,
  bankAddress: 4,
  bankCountry: 5,
};

export function AccountDetailsPage() {
  const { t } = useTranslation('accountDetails');
  const { currency, returnTo } = accountDetailsRoot.useSearch();
  const { data: accountDetailsData, isLoading } = useGetAccountDetails({
    currency,
  });

  const accountDetail = useMemo(
    () => accountDetailsData?.data,
    [accountDetailsData],
  );

  const accountDetails = useMemo(() => {
    if (!accountDetail) {
      return null;
    }

    return accountDetail.map((detail) => {
      return Object.keys(detailsOrderMap).map((key) => {
        if (key === 'bankCountry') {
          return {
            key: t(`labels.${key}`),
            value: getCountryNameFromCode((detail as any)[key]),
          };
        }

        if (key === 'routingCode') {
          return {
            key: t(`labels.routingCode.${detail.routingCodeType}`),
            value: (detail as any)[key],
          };
        }

        return {
          key: t(`labels.${key}`),
          value: (detail as any)[key],
        };
      });
    });
  }, [accountDetail, t]);

  function getAccountDetailTitle(index: number) {
    const detail = accountDetail && accountDetail[index];

    if (!detail) {
      return '';
    }

    if (
      detail.paymentType === 'local' &&
      detail.routingCodeType === 'bic_swift'
    ) {
      return 'IBAN';
    }

    return t(detail.routingCodeType);
  }

  if (isLoading || !accountDetails) {
    return (
      <PageWrapper className="flex flex-col gap-8">
        <Skeleton className="h-8 w-40 rounded-full" />
        <Skeleton className="h-8 w-80 rounded-full" />

        <Skeleton className="mt-8 h-8 w-80 rounded-full" />
        <div className="flex flex-col gap-4">
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper className="flex flex-col">
      <Breadcrumb className="mb-10">
        <BreadcrumbList>
          <Link to={returnTo === 'dashboard' ? '/' : '/account-currencies'}>
            <BreadcrumbItem>
              <BreadcrumbPage>
                {returnTo === 'dashboard'
                  ? t('breadcrumb.dashboard')
                  : t('breadcrumb.accountCurrencies')}
              </BreadcrumbPage>
            </BreadcrumbItem>
          </Link>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>
              {t('breadcrumb.mainAccount', {
                currency,
              })}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <header className="mb-16 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">{t('title')}</h1>
        </div>
      </header>

      {accountDetails &&
        accountDetails.length > 0 &&
        accountDetails.map((details, index) => (
          <section className="mb-10 w-1/2" key={index}>
            <div className="flex w-full items-center justify-between">
              <h3 className="text-xl font-medium">
                {getAccountDetailTitle(index)}
              </h3>
              <Button
                variant="subtleDark"
                onClick={async () => {
                  if ('clipboard' in navigator) {
                    const formatted = accountDetails[index]
                      .map((detail) => `${detail.key}\n${detail.value}`)
                      .join('\n');
                    await navigator.clipboard.writeText(formatted);
                    toast.message(t('copied'));
                  }
                }}
              >
                {t('copyAccountDetails')}
              </Button>
            </div>
            <DetailsCard data={details} />
          </section>
        ))}
    </PageWrapper>
  );
}

export default AccountDetailsPage;
