import { useTranslation } from 'react-i18next';

import { Combobox, ComboboxItem } from './combobox';
import { tz } from '@agyt/shared/util/common';

export interface TzPickerProps {
  value?: string;
  onChange: (value: ComboboxItem | undefined) => void;
}

export function TzPicker({ value, onChange }: TzPickerProps) {
  const { t } = useTranslation('common');

  const timezones: ComboboxItem[] = [];
  tz.forEach((value, label) => {
    timezones.push({
      value,
      label,
    });
  });

  return (
    <Combobox<ComboboxItem>
      items={timezones}
      emptyLabel={t('timezone.empty')}
      placeholder={t('timezone.placeholder')}
      selectedItem={timezones.find((tz) => tz.value === value)}
      customKeyCb={(i) => `${i.label}::${i.value}`}
      onSelect={onChange}
      resultClassName="max-h-[150px]"
      searchByLabel={true}
    />
  );
}

export default TzPicker;
