import { Link, useParams } from '@tanstack/react-router';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  LoadingButton,
  PageWrapper,
  Skeleton,
  toast,
} from '@agyt/client/shared/ui/components';
import { useUser } from '@agyt/client/web/core/user';
import {
  useDownloadStatement,
  useGetFirstTransaction,
} from '@agyt/client/web/data-access/api';
import { LocalDate, SupportedCurrency } from '@agyt/shared/util/common';

function StatementsSkeleton() {
  return (
    <div className="mt-5 flex flex-col gap-5">
      <Skeleton className="h-10 w-[200px] rounded-full" />
      <Skeleton className="h-10 w-[400px] rounded-full" />
      <Skeleton className="h-10 w-[400px] rounded-full" />
      <Skeleton className="h-10 w-[400px] rounded-full" />
      <Skeleton className="h-10 w-[400px] rounded-full" />
      <Skeleton className="h-10 w-[400px] rounded-full" />
    </div>
  );
}

export function StatementsPage() {
  const { t } = useTranslation('transactions:statements');
  const { currency } = useParams({ strict: false });
  const { data: firstTransactionData, isLoading } = useGetFirstTransaction({
    currency,
  });
  const { locale, tz } = useUser();
  const downloadStatement = useDownloadStatement();
  const [buttonInfo, setButtonInfo] = useState<{
    format: 'csv' | 'pdf' | null;
    month: number | null;
  }>({
    format: null,
    month: null,
  });

  const statementSections = useMemo(() => {
    if (!firstTransactionData?.data) {
      return null;
    }

    const today = DateTime.now();
    let start = DateTime.fromISO(firstTransactionData?.data?.completedAt);

    const result: { [key: string]: number[] } = {};

    while (start <= today) {
      const year = start.year;
      const month = start.toFormat('M');

      if (!result[year]) {
        result[year] = [];
      }

      const zeroBasedMonth = +month - 1;
      result[year].push(zeroBasedMonth);
      start = start.plus({ months: 1 });
    }

    return result;
  }, [firstTransactionData]);

  async function triggerDownload({
    currency,
    year,
    month,
    format,
  }: {
    currency: SupportedCurrency;
    year: string;
    month: string;
    format: 'csv' | 'pdf';
  }) {
    const startDate = `${year}-${month}-01`;
    const startDateWithTz = new LocalDate({
      timestamp: startDate,
      locale,
      tz,
    }).toISO();

    const endDate = new LocalDate({
      timestamp: startDateWithTz,
      locale,
      tz,
    })
      .manipulate((date) => date.endOf('month'))
      .toISO();

    const blob = await downloadStatement.mutateAsync({
      currency,
      startDate: startDateWithTz,
      endDate,
      format,
    });

    const label = `${year}-${month}`;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${currency}-${label}.${format}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    setButtonInfo({ format: null, month: null });

    toast.success(
      t('notifications.generated', { ns: 'transactions:statements' }),
    );
  }

  return (
    <PageWrapper>
      <Breadcrumb className="mb-10">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>
              <Link to="/account-currencies" className="cursor-pointer">
                {t('title', { ns: 'accountCurrencies' })}
              </Link>
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{t('title')}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">
            {t('title')} - {currency}
          </h1>
        </div>
      </header>
      <div className="mt-10">
        {isLoading && <StatementsSkeleton />}

        {!isLoading && !statementSections && <div>{t('empty.copy')}</div>}

        {statementSections &&
          Object.keys(statementSections)
            .sort((a, b) => +b - +a)
            .map((key) => (
              <section className="my-6" key={key}>
                <h2 className="mb-4 text-3xl font-medium text-slate-900">
                  {key}
                </h2>
                <ol className="my-2 flex max-w-max flex-col gap-2">
                  {statementSections[key]
                    .sort((a: number, b: number) => b - a)
                    .map((month, idx) => (
                      <li key={key + idx}>
                        <div className="flex w-[320px] items-center justify-between rounded-lg bg-slate-100 px-2 py-1">
                          <span>
                            {t(`calendar.months.${month}`, { ns: 'common' })}
                          </span>

                          <div className="flex gap-2">
                            <LoadingButton
                              isLoading={
                                buttonInfo.format === 'csv' &&
                                buttonInfo.month === month &&
                                downloadStatement.isPending
                              }
                              onClick={() => {
                                if (currency) {
                                  setButtonInfo({ format: 'csv', month });
                                  triggerDownload({
                                    currency,
                                    year: key,
                                    month: `${month + 1}`.padStart(2, '0'),
                                    format: 'csv',
                                  });
                                }
                              }}
                              className="w-[60px]"
                            >
                              CSV
                            </LoadingButton>

                            <LoadingButton
                              isLoading={
                                buttonInfo.format === 'pdf' &&
                                buttonInfo.month === month &&
                                downloadStatement.isPending
                              }
                              onClick={() => {
                                if (currency) {
                                  setButtonInfo({ format: 'pdf', month });
                                  triggerDownload({
                                    currency,
                                    year: key,
                                    month: `${month + 1}`.padStart(2, '0'),
                                    format: 'pdf',
                                  });
                                }
                              }}
                              className="w-[60px]"
                            >
                              PDF
                            </LoadingButton>
                          </div>
                        </div>
                      </li>
                    ))}
                </ol>
              </section>
            ))}
      </div>
    </PageWrapper>
  );
}

export default StatementsPage;
