import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@agyt/client/shared/util/theme';

const gradientCircleVariants = cva(
  'flex items-center justify-center rounded-full text-xs leading-8',
  {
    variants: {
      variant: {
        default: 'text-blue-700 bg-gradient-to-b from-blue-100 to-[#A9B9F8]',
        blue: 'text-blue-700 bg-gradient-to-b from-blue-100 to-[#A9B9F8]',
        slate: 'text-slate-300 bg-slate-100',
        lime: 'text-lime-800 bg-gradient-to-b from-lime-200 to-[#CCD986]',
        violet: 'text-violet-700 bg-gradient-to-b from-[#F3E8FF] to-[#C9ADE7]',
        teal: 'text-teal-700 bg-gradient-to-b from-teal-100 to-[#8DBBB1]',
        orange: 'text-orange-700 bg-gradient-to-b from-orange-50 to-[#F2C995]',
      },
      size: {
        default: 'h-[45px] w-[45px]',
        small: 'h-[23px] w-[23px] text-[6px] leading-4 font-semibold',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface GradientCircleProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof gradientCircleVariants> {
  asChild?: boolean;
}

const GradientCircle = React.forwardRef<HTMLDivElement, GradientCircleProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';
    return (
      <Comp
        className={cn(gradientCircleVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

export { GradientCircle, gradientCircleVariants };
