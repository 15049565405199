import { useHttpClient } from '@agyt/client/web/core/http';
import {
  AvailableCurrenciesResponse,
  SuccessApiResponse,
} from '@agyt/shared/types';
import { SupportedCurrency } from '@agyt/shared/util/common';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';

export function useGetAvailableCurrencies() {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: ['account-currencies:available-currencies'],
    async queryFn() {
      const res = await httpClient.get(
        '/account-currencies/available-currencies',
      );
      return res?.data as SuccessApiResponse<AvailableCurrenciesResponse[]>;
    },
    placeholderData: keepPreviousData,
  });
}

export function useCreateAccountCurrencies() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async (currency: SupportedCurrency) => {
      const res = await httpClient.post(
        '/account-currencies/add-account-currencies',
        {
          currency,
        },
      );
      return res?.data as SuccessApiResponse<any>;
    },
    scope: {
      id: 'createAccountCurrencies',
    },
  });
}
