import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';

import { SupportedCurrency } from '@agyt/shared/util/common';
import { Button, CurrencyFlag } from '@agyt/client/shared/ui/components';

export function AccountCard({
  name,
  balance,
  returnTo,
  currency,
}: {
  name: string;
  balance: string;
  currency: SupportedCurrency;
  returnTo: string;
}) {
  const { t } = useTranslation('common');
  return (
    <div className="flex h-[179px] min-w-[363px] rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex h-full w-full flex-col">
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col">
            <Link to="/transactions" search={{ currency }}>
              <div className="mb-4 text-base leading-7 text-slate-500">
                {name}
              </div>
            </Link>
            <div className="text-xl font-medium text-slate-900">{balance}</div>
          </div>
          <CurrencyFlag
            currency={currency}
            alt={currency}
            className="h-[45px] w-[45px]"
          />
        </div>
        <div className="flex flex-1 items-end">
          <div className="flex w-full items-center justify-between">
            <div className="flex gap-3">
              <Button variant="subtle" size="sm" asChild>
                <Link to={`/transactions/statements/${currency}`}>
                  {t('buttons.downloadStatement', { ns: 'transactions' })}
                </Link>
              </Button>
            </div>
            <div>
              <Button variant="link" size="sm" asChild>
                <Link to="/account-details" search={{ currency, returnTo }}>
                  {t('actions.account_details')}
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountCard;
