import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from '@tanstack/react-router';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  AmountInput,
  Button,
  ChargeTypePicker,
  DatePicker,
  DetailsCard,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  LoadingButton,
  PageWrapper,
  PaymentPurposeCodePicker,
  Progress,
  RadioGroup,
  RadioGroupItem,
  Spinner,
} from '@agyt/client/shared/ui/components';
import { IconCheckCircle, IconPlusCircle } from '@agyt/client/shared/ui/icons';
import { useUser } from '@agyt/client/web/core/user';
import {
  useCreatePayment,
  useGetBeneficiaryAccounts,
  useGetUserProfile,
  usePaymentDates,
  useValidatePayment,
} from '@agyt/client/web/data-access/api';
import { CreatePaymentPayloadBody } from '@agyt/shared/types';
import {
  getCountryNameFromCode,
  Money,
  stripEmptyStrings,
  SupportedCurrency,
} from '@agyt/shared/util/common';
import { BeneficiaryItem, BeneficiaryPicker } from './beneficiary-picker';
import { payRoot } from './router';

const purposeCodeCountries = ['BH', 'CN', 'IN', 'MY', 'AE'];

const enum CreatePaymentStep {
  'PAYMENT_DETAILS',
  'REVIEW',
  'VERIFY',
  'SUCCESS',
}

const beneficiaryDetailsOrderMap = {
  name: 0,
  beneficiaryType: 1,
  iban: 2,
  accountNumber: 3,
  bic: 4,
  sortCode: 5,
  aba: 6,
  bsbCode: 7,
  institutionNumber: 8,
  bankCode: 9,
  branchCode: 10,
  clabe: 11,
  cnaps: 12,
  beneficiaryAddress: 13,
  beneficiaryCity: 14,
  beneficiaryCountry: 15,
};

const paymentDetailsOrderMap = {
  paymentType: 0,
  amount: 1,
  fee: 2,
  reference: 3,
  reason: 4,
  paymentDate: 5,
};

const paymentVerificationDetailsOrderMap = {
  amount: 0,
  beneficiary: 1,
};

export function CreatePaymentPage() {
  const { t } = useTranslation('pay');
  const searchParams = payRoot.useSearch();
  const { locale } = useUser();
  const [beneficiaryId, setBeneficiaryId] = useState<string | undefined>();
  const [beneficiary, setBeneficiary] = useState<BeneficiaryItem>();
  const [step, setStep] = useState<CreatePaymentStep>(
    CreatePaymentStep.PAYMENT_DETAILS,
  );
  const [progress, setProgress] = useState(25);
  const [fee, setFee] = useState<{
    currency: SupportedCurrency;
    amount: number;
  } | null>(null);
  const [validatedPayment, setValidatedPayment] =
    useState<Partial<CreatePaymentPayloadBody>>();
  const [isResendEnabled, setIsResendEnabled] = useState<boolean>(false);
  const [notReceivedCode, setNotReceivedCode] = useState<boolean>(false);
  const { data: accountsData, isFetching: isAccountDataFetching } =
    useGetBeneficiaryAccounts(beneficiary?.id, false, 3);
  const { data: userProfileData, refetch: refetchUserProfile } =
    useGetUserProfile();
  const userProfile = userProfileData?.data;

  const validatePayment = useValidatePayment();
  const createPayment = useCreatePayment();

  useEffect(() => {
    if (searchParams.beneficiaryId) {
      setBeneficiaryId(searchParams.beneficiaryId);
    }
  }, [searchParams]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsResendEnabled(true);
    }, 60000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    refetchUserProfile();
  }, [refetchUserProfile]);

  // In the first version we'll have 1 to 1 mapping between Beneficiaries and Account details, event though we have the support for 1:n.
  const currentAccount = useMemo(() => {
    if (!accountsData?.data?.length) {
      return null;
    }

    return accountsData.data[0];
  }, [accountsData]);

  const beneficiaryDetails = useMemo(() => {
    if (!currentAccount) {
      return null;
    }

    return Object.keys(beneficiaryDetailsOrderMap)
      .map((key, value) => {
        if (key === 'beneficiaryType') {
          return {
            key: t(`labels.${key}`, { ns: 'beneficiaries:details' }),
            value: t(`values.${currentAccount[key]}`, {
              ns: 'beneficiaries:details',
            }),
          };
        }
        if (key === 'beneficiaryAddress') {
          return {
            key: t(`labels.${key}`, { ns: 'beneficiaries:details' }),
            value: `${(currentAccount[key] as unknown as string[]).join(',')}`, // because CC returns a string[] even though a string is sent as input
          };
        }

        if (key === 'beneficiaryCountry') {
          return {
            key: t(`labels.${key}`, { ns: 'beneficiaries:details' }),
            value: getCountryNameFromCode(currentAccount[key]),
          };
        }

        return {
          key: t(`labels.${key}`, { ns: 'beneficiaries:details' }),
          value: (currentAccount as any)[key],
        };
      })
      .filter((o) => o.value);
  }, [currentAccount, t]);

  const paymentDetails = useMemo(() => {
    if (!validatedPayment || !currentAccount) {
      return null;
    }

    return Object.keys({
      ...paymentDetailsOrderMap,
      ...(validatedPayment.paymentType === 'swift' && { chargeType: 6 }),
      ...(purposeCodeCountries.includes(currentAccount?.beneficiaryCountry) && {
        purposeCode: 7,
      }),
    })
      .map((key, value) => {
        if (key === 'paymentType') {
          return {
            key: t(`labels.${key}`),
            value: t(`values.paymentType.${validatedPayment[key]}`),
          };
        }

        if (key === 'fee') {
          return {
            key: t(`labels.${key}`),
            value: fee?.amount
              ? new Money({
                  amount: fee?.amount,
                  currency: fee?.currency,
                  locale,
                }).format()
              : null,
          };
        }

        if (key === 'amount') {
          return {
            key: t(`labels.${key}`),
            value: new Money({
              amount: (validatedPayment.amount ?? 0) as number,
              currency: validatedPayment.currency ?? '',
              locale,
            }).format(),
          };
        }

        if (key === 'chargeType') {
          return {
            key: t(`labels.${key}`),
            value: validatedPayment[key]?.toUpperCase(),
          };
        }

        if (key === 'purposeCode') {
          return {
            key: t(`labels.${key}`),
            value: validatedPayment[key],
          };
        }

        return {
          key: t(`labels.${key}`),
          value: (validatedPayment as any)[key],
        };
      })
      .filter((o) => o.value);
  }, [validatedPayment, t, fee, locale, currentAccount]);

  const { data: paymentsData } = usePaymentDates({
    currency: currentAccount?.currency,
  });

  const disabledDays = () =>
    paymentsData &&
    paymentsData.data.invalidPaymentDates &&
    Object.keys(paymentsData.data.invalidPaymentDates).map((d) => new Date(d));

  const defaultValues = useMemo(() => {
    const emptyValues = {
      paymentType: '',
      amount: '',
      reference: '',
      reason: '',
      purposeCode: '',
      chargeType: '',
    };
    if (!currentAccount) {
      return emptyValues;
    }

    const paymentType =
      currentAccount.paymentTypes?.length > 1
        ? ''
        : currentAccount.paymentTypes[0];

    return {
      ...emptyValues,
      paymentType,
    };
  }, [currentAccount]);

  const paymentDetailsForm = z
    .object({
      paymentType: z.string().min(1, {
        message: 'required',
      }),
      amount: z.string().min(1, {
        message: 'required',
      }),
      reference: z.string().min(1, {
        message: 'required',
      }),
      reason: z.string().min(1, {
        message: 'required',
      }),
      paymentDate: z.string().optional(),
      purposeCode: z.string().optional(),
      chargeType: z.string().optional(),
    })
    .refine(
      (data) => {
        if (data.paymentType === 'swift') {
          return data.chargeType && data.chargeType.length > 0;
        }
        return true;
      },
      {
        message: 'required',
        path: ['chargeType'],
      },
    )
    .refine(
      (data) => {
        const shouldRequirePurposeCode =
          currentAccount &&
          purposeCodeCountries.includes(currentAccount.beneficiaryCountry);
        return (
          !shouldRequirePurposeCode ||
          (data.purposeCode && data.purposeCode.length > 0)
        );
      },
      {
        message: 'required',
        path: ['purposeCode'],
      },
    );

  type PaymentDetailsForm = z.infer<typeof paymentDetailsForm>;

  const form = useForm<PaymentDetailsForm>({
    resolver: zodResolver(paymentDetailsForm),
    values: defaultValues,
  });

  const verificationFormObj = z.object({
    code: z.string().min(6, {
      message: 'required',
    }),
  });
  type VerificationForm = z.infer<typeof verificationFormObj>;

  const verificationForm = useForm<VerificationForm>({
    resolver: zodResolver(verificationFormObj),
    values: {
      code: '',
    },
  });

  const paymentVerificationDetails = useMemo(() => {
    if (!validatedPayment || !currentAccount) {
      return [];
    }
    return Object.keys(paymentVerificationDetailsOrderMap)
      .map((key) => {
        if (key === 'amount') {
          return {
            key: t(`verification.labels.${key}`),
            value: new Money({
              amount: (validatedPayment.amount ?? 0) as number,
              currency: validatedPayment.currency ?? '',
              locale,
            }).format(),
          };
        } else {
          return {
            key: t(`verification.labels.${key}`),
            value: currentAccount.name,
          };
        }
      })
      .filter((item) => item.value);
  }, [validatedPayment, currentAccount, t, locale]);

  const hasMultiplePaymentTypes = useMemo(
    () =>
      currentAccount?.paymentTypes && currentAccount?.paymentTypes?.length > 1,
    [currentAccount],
  );

  async function onValidate(data: PaymentDetailsForm) {
    try {
      let payload = {
        ...data,
        ...(data.paymentType === 'swift' && { chargeType: data.chargeType }),
        beneficiaryId: currentAccount?.id,
        currency: currentAccount?.currency,
      };

      payload = stripEmptyStrings(payload);
      const feeData = await validatePayment.mutateAsync(payload);
      setFee(feeData.data.fee);

      const uniqueId = uuidv4();
      const validatedPayload = { ...payload, uniqueRequestId: uniqueId };
      setValidatedPayment(validatedPayload);
      setStep(CreatePaymentStep.REVIEW);
      setProgress(50);
    } catch (error) {
      // TODO handle errors
      console.log(error);
    }
  }

  async function onVerify() {
    try {
      setStep(CreatePaymentStep.VERIFY);
      setProgress(75);
    } catch (error) {
      // @TODO handle errors
      console.error('ERROR:', error);
    }
  }

  async function onResend() {
    setIsResendEnabled(false);
    setNotReceivedCode(true);
    console.log('Resend');
  }

  async function onBack() {
    setStep(CreatePaymentStep.PAYMENT_DETAILS);
  }

  async function confirmPayment() {
    try {
      await createPayment.mutateAsync(validatedPayment);
      setStep(CreatePaymentStep.SUCCESS);
    } catch (error) {
      // @TODO handle errors
      console.error('ERROR:', error);
    }
  }

  return (
    <PageWrapper className="flex flex-col items-center">
      <div className="flex h-full w-[555px] flex-col">
        {step !== CreatePaymentStep.SUCCESS && (
          <header className="flex flex-col items-center justify-between">
            <div className="relative w-full">
              <Progress value={progress} />
            </div>
          </header>
        )}
        <div className="h-full">
          {step === CreatePaymentStep.PAYMENT_DETAILS && (
            <section>
              <div className="mb-16 mt-14 w-full">
                <h2 className="text-3xl font-medium">{t('details.title')}</h2>
              </div>

              <Form {...form}>
                <form
                  className="mt-16"
                  onSubmit={form.handleSubmit(onValidate)}
                >
                  <div className="flex items-center gap-4">
                    <div className="mb-4 w-2/3">
                      <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                        {t('labels.beneficiary')}
                      </FormLabel>
                      <BeneficiaryPicker
                        prefillId={beneficiaryId}
                        value={beneficiary}
                        onChange={(v) => {
                          setBeneficiary(v);
                          if (beneficiaryId) {
                            window.history.replaceState({}, '', '/pay');
                          }
                        }}
                      />
                    </div>
                    <div className="mt-2">
                      <Link
                        to="/beneficiaries/new"
                        search={{ returnTo: '/pay' }}
                      >
                        <Button
                          variant="ghost"
                          className="text-blue-500"
                          type="button"
                        >
                          <IconPlusCircle />
                          <span className="ml-2">{t('addBeneficiary')}</span>
                        </Button>
                      </Link>
                    </div>
                  </div>

                  {isAccountDataFetching && (
                    <div className="flex justify-center">
                      <Spinner />
                    </div>
                  )}

                  {beneficiary && !currentAccount && !isAccountDataFetching && (
                    <>{t('errors.no_account_data')}</>
                  )}

                  {currentAccount && !isAccountDataFetching && (
                    <>
                      {hasMultiplePaymentTypes && (
                        <FormField
                          control={form.control}
                          name="paymentType"
                          render={({ field }) => (
                            <FormItem className="mb-4">
                              <FormControl>
                                <RadioGroup
                                  onValueChange={field.onChange}
                                  defaultValue={field.value}
                                  className="flex gap-4"
                                  {...field}
                                >
                                  <FormItem className="flex-1">
                                    <FormLabel className="item-center flex h-10 flex-1 rounded-md border border-slate-200 bg-white p-3 text-slate-900 hover:cursor-pointer">
                                      <FormControl>
                                        <RadioGroupItem value="local" />
                                      </FormControl>
                                      <span className="ml-2">
                                        {t('values.paymentType.local')}
                                      </span>
                                    </FormLabel>
                                  </FormItem>
                                  <FormItem className="flex-1">
                                    <FormLabel className="item-center flex h-10 flex-1 rounded-md border border-slate-200 bg-white p-3 text-slate-900 hover:cursor-pointer">
                                      <FormControl>
                                        <RadioGroupItem value="swift" />
                                      </FormControl>
                                      <span className="ml-2">
                                        {t('values.paymentType.swift')}
                                      </span>
                                    </FormLabel>
                                  </FormItem>
                                </RadioGroup>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}

                      <FormField
                        control={form.control}
                        name="amount"
                        render={({ field }) => (
                          <FormItem className="mb-4">
                            <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                              {t('labels.amount')}
                            </FormLabel>
                            <FormControl>
                              <AmountInput
                                {...field}
                                suffix={currentAccount.currency}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="reference"
                        render={({ field }) => (
                          <FormItem className="mb-4">
                            <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                              {t('labels.reference')}
                            </FormLabel>
                            <FormControl>
                              <Input {...field}></Input>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="reason"
                        render={({ field }) => (
                          <FormItem className="mb-4">
                            <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                              {t('labels.reason')}
                            </FormLabel>
                            <FormControl>
                              <Input {...field}></Input>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      {purposeCodeCountries.includes(
                        currentAccount.beneficiaryCountry,
                      ) && (
                        <FormField
                          control={form.control}
                          name="purposeCode"
                          render={({ field }) => (
                            <FormItem className="mb-4">
                              <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                                {t('labels.purposeCode')}
                              </FormLabel>
                              <FormControl>
                                <PaymentPurposeCodePicker
                                  value={field.value}
                                  onChange={(code) =>
                                    field.onChange(code?.value)
                                  }
                                  bankAccountCountry={
                                    currentAccount.bankCountry
                                  }
                                  currency={
                                    currentAccount.currency as SupportedCurrency
                                  }
                                  entityType={currentAccount.beneficiaryType}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}

                      <FormField
                        control={form.control}
                        name="paymentDate"
                        render={({ field }) => (
                          <FormItem className="mb-4">
                            <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                              {t('labels.date')}
                            </FormLabel>
                            <FormControl>
                              <div>
                                <DatePicker
                                  className="w-full"
                                  disabled={disabledDays()}
                                  date={field.value}
                                  onSelect={field.onChange}
                                />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      {form.watch('paymentType') === 'swift' && (
                        <FormField
                          control={form.control}
                          name="chargeType"
                          render={({ field }) => (
                            <FormItem className="mb-4">
                              <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                                {t('labels.chargeType')}
                              </FormLabel>
                              <FormControl>
                                <ChargeTypePicker
                                  value={field.value}
                                  onChange={(type) =>
                                    field.onChange(type?.value)
                                  }
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}

                      <Accordion type="single" collapsible>
                        <AccordionItem value="item-1">
                          <AccordionTrigger className="border-0 bg-transparent px-1 text-sm font-medium leading-6 text-blue-500">
                            {t('details.beneficiaryDetailsSectionTrigger')}
                          </AccordionTrigger>
                          <AccordionContent>
                            {beneficiaryDetails && (
                              <DetailsCard data={beneficiaryDetails} />
                            )}
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>

                      <div className="mt-16 flex gap-4">
                        <LoadingButton
                          type="submit"
                          className="flex-1"
                          isLoading={validatePayment.isPending}
                        >
                          {t('actions.next', { ns: 'common' })}
                        </LoadingButton>
                      </div>
                    </>
                  )}
                </form>
              </Form>
            </section>
          )}
          {step === CreatePaymentStep.REVIEW && (
            <section>
              <div className="mb-16 mt-14 w-full">
                <h2 className="text-3xl font-medium">{t('review.title')}</h2>
              </div>
              <div>
                <h3 className="mb-4 text-xl font-medium text-slate-900">
                  {t('review.beneficarySectionTitle')}
                </h3>
                <div>
                  {beneficiaryDetails && (
                    <DetailsCard data={beneficiaryDetails} />
                  )}
                </div>
              </div>
              <div className="mt-10">
                <h3 className="mb-4 text-xl font-medium text-slate-900">
                  {t('review.paymentSectionTitle')}
                </h3>
                <div>
                  {paymentDetails && <DetailsCard data={paymentDetails} />}
                </div>
              </div>
              <div className="mt-16 flex gap-4">
                <Button
                  className="flex-1"
                  variant="subtleDark"
                  onClick={() => {
                    setStep(CreatePaymentStep.PAYMENT_DETAILS);
                    setProgress(25);
                  }}
                >
                  {t('actions.back', { ns: 'common' })}
                </Button>
                <LoadingButton
                  className="flex-1"
                  onClick={onVerify}
                  isLoading={createPayment.isPending}
                >
                  {t('actions.createPayment')}
                </LoadingButton>
              </div>
            </section>
          )}
          {step === CreatePaymentStep.VERIFY && (
            <section className="mt-20">
              <p className="mb-8 text-center text-xl text-slate-900">
                {t('verification.title')}
              </p>
              <p className="mb-8 text-center text-base text-slate-500">
                {t('verification.subtitle', {
                  number: userProfile?.contact.phoneNumber,
                })}
              </p>
              <div className="mb-8 w-full">
                <DetailsCard data={paymentVerificationDetails} />
              </div>
              <Form {...verificationForm}>
                <form onSubmit={verificationForm.handleSubmit(confirmPayment)}>
                  <div className="flex w-full items-center justify-between gap-2">
                    <FormField
                      control={verificationForm.control}
                      name="code"
                      render={({ field }) => (
                        <FormItem className="mb-4 flex-1">
                          <FormControl>
                            <Input
                              {...field}
                              placeholder={t('verification.code')}
                            ></Input>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <Button
                      className="mb-4"
                      variant="subtleDark"
                      type="button"
                      disabled={!isResendEnabled}
                      onClick={onResend}
                    >
                      {t('verification.resend')}
                    </Button>
                  </div>
                  <div className="mb-8 flex items-center justify-center gap-2">
                    <Button
                      onClick={() => {
                        setStep(CreatePaymentStep.PAYMENT_DETAILS);
                        setProgress(25);
                      }}
                      className="flex-1"
                      variant="subtleDark"
                    >
                      {t('verification.back')}
                    </Button>
                    <Button
                      className="flex-1"
                      type="submit"
                      disabled={!verificationForm.formState.isValid}
                    >
                      {t('verification.confirm')}
                    </Button>
                  </div>

                  {notReceivedCode && (
                    <p className="mt-8 text-center text-sm text-slate-500">
                      {t('verification.notReceived')}
                    </p>
                  )}
                </form>
              </Form>
            </section>
          )}
          {step === CreatePaymentStep.SUCCESS && (
            <section className="mt-14 flex flex-col items-center">
              <IconCheckCircle />
              <h2 className="my-9 text-xl font-medium text-slate-900">
                {t('success.title')}
              </h2>
              <Link to={'/transactions'}>
                <Button>{t('actions.close', { ns: 'common' })}</Button>
              </Link>
            </section>
          )}
        </div>
      </div>
    </PageWrapper>
  );
}

export default CreatePaymentPage;
