import { SupportedCurrency } from '@agyt/shared/util/common';
import * as currencies from './currency-assets';

export function CurrencyFlag({
  currency,
  alt,
  className,
}: {
  currency: SupportedCurrency;
  alt?: string;
  className?: string;
}) {
  return <img src={currencies[currency]} alt={alt} className={className} />;
}

export default CurrencyFlag;
