import { createRouter, Navigate } from '@tanstack/react-router';
import {
  inactivityRoute,
  indexRoute,
  rootRoute,
  welcomeRoute,
} from '@agyt/client/web/feature/common';
import { dashboardRoute } from '@agyt/client/web/feature/dashboard';
import { beneficiariesRoutes } from '@agyt/client/web/feature/beneficiaries';
import {
  payRoutes,
  transactionsRoutes,
} from '@agyt/client/web/feature/transactions';
import { accountCurrenciesRoutes } from '@agyt/client/web/feature/account-currencies';
import { currencyExchangeRoutes } from '@agyt/client/web/feature/currency-exchange';
import { accountDetailsRoutes } from '@agyt/client/web/feature/account-details';
import { settingsRoutes } from '@agyt/client/web/feature/settings';

const routeTree = rootRoute.addChildren([
  indexRoute.addChildren([
    dashboardRoute,
    beneficiariesRoutes,
    transactionsRoutes,
    payRoutes,
    accountCurrenciesRoutes,
    currencyExchangeRoutes,
    accountDetailsRoutes,
    settingsRoutes,
  ]), // This is purposefully composed here and not in the indexRoute definition to provide a clear global route tree
  welcomeRoute,
  inactivityRoute,
]);

export const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  context: { auth: undefined! },
  defaultNotFoundComponent: () => <Navigate to="/welcome" />,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: unknown; // TODO: Define the type
  }
}
