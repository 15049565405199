// @TODO Validate against currency cloud rates endpoint
export const supportedCurrencies = [
  'USD',
  'EUR',
  'GBP',
  'AED',
  'BHD',
  'AUD',
  'CHF',
  'CNY',
  'CAD',
  'DKK',
  'CZK',
  'HUF',
  'HKD',
  'JPY',
  'KES',
  'ILS',
  'KWD',
  'MXN',
  'NOK',
  'PLN',
  'OMR',
  'NZD',
  'QAR',
  'RON',
  'SAR',
  'THB',
  'SEK',
  'SGD',
  'UGX',
  'TRY',
  'ZAR',
  'PHP',
  'MYR',
] as const;

export type SupportedCurrency = (typeof supportedCurrencies)[number];

export type AmountWithCurrency = {
  amount: number;
  currency: SupportedCurrency;
};

export type MoneyOptions = {
  amount: number;
  currency: string;
  locale: string;
  currencyDisplay?: CurrencyDisplay;
};

export enum CurrencyDisplay {
  'CODE' = 'code',
  'SYMBOL' = 'symbol',
  'NONE' = 'none',
}

export class Money {
  private readonly amount: number;
  private readonly currency: SupportedCurrency;
  private locale: string;
  private currencyDisplay: CurrencyDisplay;

  constructor({ amount, currency, locale, currencyDisplay }: MoneyOptions) {
    try {
      this.amount = amount;
      this.currency = Money.getAsSupportedCurrency(currency);
      this.currencyDisplay = currencyDisplay ?? CurrencyDisplay.CODE;

      Intl.getCanonicalLocales(locale);
      this.locale = locale;
    } catch (err: unknown) {
      if (err instanceof RangeError) {
        throw new Error(err.message);
      }
      throw err;
    }
  }

  get value(): AmountWithCurrency {
    return {
      amount: this.amount,
      currency: this.currency,
    };
  }

  format() {
    if (this.currencyDisplay === CurrencyDisplay.NONE) {
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.currency,
        currencyDisplay: CurrencyDisplay.CODE,
      })
        .format(this.amount)
        .replace(this.currency, '')
        .trim();
    }

    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currency,
      currencyDisplay: this.currencyDisplay,
    }).format(this.amount);
  }

  static getAsSupportedCurrency(currency: string) {
    if (supportedCurrencies.includes(currency as SupportedCurrency)) {
      return currency as SupportedCurrency;
    }

    throw new RangeError('Unsupported currency');
  }
}
