import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@agyt/client/shared/util/theme';
import { Spinner } from './spinner';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-slate-900 text-white hover:bg-slate-700',
        destructive: 'bg-red-500 text-white hover:bg-red-600',
        subtleDestructive: 'bg-red-100 text-red-700 hover:bg-red-200',
        outline:
          'border border-slate-300 bg-white hover:bg-slate-100 text-slate-700',
        secondary: 'bg-blue-500 text-white hover:bg-blue-800',
        subtle: 'bg-slate-100 text-slate-900 hover:bg-slate-200',
        subtleDark: 'bg-slate-200 text-slate-900 hover:bg-slate-300',
        ghost: 'hover:bg-slate-100 text-slate-700',
        link: 'text-blue-500 underline-offset-4 hover:underline',
        nav: 'text-slate-500 hover:bg-slate-100 hover:text-slate-900 rounded-sm',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
        nav: 'h-8 text-xs font-medium',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

const LoadingButton = React.forwardRef<
  HTMLButtonElement,
  { isLoading: boolean } & ButtonProps
>(({ className, variant, size, isLoading, children, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      className={buttonVariants({ variant, size, className })}
      disabled={isLoading}
      {...props}
    >
      {isLoading ? (
        <Spinner className="h-4 w-4 fill-slate-100 text-slate-400" />
      ) : (
        children
      )}
    </Button>
  );
});

export { Button, LoadingButton, buttonVariants };
