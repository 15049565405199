import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from './button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './dialog';
import { Progress } from './progress';

const EXPIRE_TIME = 30;

export function InactivityDialog({
  dismiss,
  open,
}: {
  open: boolean;
  dismiss: (open: boolean) => void;
}) {
  const { t } = useTranslation('common');
  const { logout } = useAuth0();
  const [expireTime, setExpireTime] = useState(EXPIRE_TIME);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (expireTime < 0) {
      return;
    }

    const timeInterval = setInterval(() => {
      setExpireTime((prevTime) => prevTime - 1);
    }, 1000);

    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        const decrementAmount = 100 / (EXPIRE_TIME * 10);
        const newProgress = prevProgress - decrementAmount;
        if (newProgress <= 0) {
          clearInterval(progressInterval);
          return 0;
        }
        return newProgress;
      });
    }, 100);

    return () => {
      clearInterval(timeInterval);
      clearInterval(progressInterval);
    };
  }, [expireTime]);

  function handleLogout(e: React.MouseEvent) {
    e.stopPropagation();
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }

  function handleContinue(e: React.MouseEvent) {
    e.stopPropagation();
    dismiss(false);
  }

  return (
    <Dialog open={open}>
      <DialogContent className="gap-0 text-slate-900 [&>button]:hidden">
        <DialogHeader className="mb-6 justify-center">
          <DialogTitle className="text-center text-2xl font-medium">
            {t('inactivityDialog.title')}
          </DialogTitle>
        </DialogHeader>
        <div className="mb-12">
          <DialogDescription className="flex flex-col items-center text-base font-medium">
            {t('inactivityDialog.sessionWithCount', {
              count: expireTime,
            })}
          </DialogDescription>
          <Progress value={progress} className="mt-4 h-1 w-full" />
        </div>
        <DialogFooter className="gap-6 sm:justify-center">
          <DialogClose asChild onClick={handleLogout}>
            <Button type="button" variant="subtle">
              {t('inactivityDialog.logout')}
            </Button>
          </DialogClose>
          <DialogClose asChild onClick={handleContinue}>
            <Button variant="default">
              {t('inactivityDialog.keepSession')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default InactivityDialog;
