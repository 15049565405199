import { useAuth0 } from '@auth0/auth0-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import {
  Button,
  Combobox,
  ComboboxItem,
  CurrencyPicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  PageWrapper,
  Skeleton,
  TzPicker,
} from '@agyt/client/shared/ui/components';
import { useUser } from '@agyt/client/web/core/user';
import {
  useGetUserProfile,
  useUpdateUserProfile,
} from '@agyt/client/web/data-access/api';
import { LocalDate } from '@agyt/shared/util/common';

const contactFormSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  dateOfBirth: z.string().optional(),
  phoneNumber: z.string().optional(),
  email: z.string().optional(),
});
export type ContactForm = z.infer<typeof contactFormSchema>;

const profileFormSchema = z.object({
  language: z.string().min(1, { message: 'required' }),
  preferredCurrency: z.string().min(1, { message: 'required' }),
  tz: z.string().min(1, { message: 'required' }),
});
export type UserProfileForm = z.infer<typeof profileFormSchema>;

function LoadingSkeleton() {
  return (
    <div className="grid grid-cols-3 gap-8">
      <div className="col-span-1 flex flex-col gap-8">
        <Skeleton className="h-10 w-full rounded-full" />
        <Skeleton className="h-10 w-full rounded-full" />
        <Skeleton className="h-10 w-full rounded-full" />
        <Skeleton className="h-10 w-full rounded-full" />
        <Skeleton className="h-10 w-full rounded-full" />
        <Skeleton className="h-10 w-full rounded-full" />
      </div>
      <div className="col-span-1 flex flex-col gap-8">
        <Skeleton className="h-10 w-full rounded-full" />
        <Skeleton className="h-10 w-full rounded-full" />
      </div>
    </div>
  );
}

export function SettingsPage() {
  const { t } = useTranslation('settings');
  const { getAccessTokenSilently } = useAuth0();
  const { locale, primaryCurrency, tz } = useUser();
  const {
    data: userProfileData,
    refetch: refetchUserProfile,
    isFetching,
  } = useGetUserProfile();
  const updateUserProfile = useUpdateUserProfile();
  const userProfile = userProfileData?.data;
  const languages = [
    { value: 'en-GB', label: `🇬🇧 ${t('labels.languages.en-GB')}` },
    { value: 'en-US', label: `🇺🇸 ${t('labels.languages.en-US')}` },
    { value: 'de-DE', label: `🇩🇪 ${t('labels.languages.de-DE')}` },
    { value: 'de-CH', label: `🇨🇭 ${t('labels.languages.de-CH')}` },
    { value: 'fr-FR', label: `🇫🇷 ${t('labels.languages.fr-FR')}` },
    { value: 'es-ES', label: `🇪🇸 ${t('labels.languages.es-ES')}` },
  ];

  const userProfileDefaults = useMemo(() => {
    return {
      language: locale,
      preferredCurrency: primaryCurrency,
      tz,
    };
  }, [locale, tz, primaryCurrency]);

  const contactDefaults = useMemo(() => {
    return {
      firstName: userProfile?.contact?.firstName || '',
      lastName: userProfile?.contact?.lastName || '',
      dateOfBirth: userProfile?.contact?.dateOfBirth || '',
      phoneNumber: userProfile?.contact?.phoneNumber || '',
      email: userProfile?.contact?.email || '',
    };
  }, [userProfile]);

  const profileForm = useForm<UserProfileForm>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: userProfileDefaults,
  });

  const contactForm = useForm<ContactForm>({
    resolver: zodResolver(contactFormSchema),
    defaultValues: contactDefaults,
  });

  useEffect(() => {
    profileForm.reset(userProfileDefaults);
  }, [userProfileDefaults, profileForm]);

  useEffect(() => {
    contactForm.reset(contactDefaults);
  }, [contactDefaults, contactForm]);

  useEffect(() => {
    refetchUserProfile();
  }, [refetchUserProfile]);

  async function onSubmit() {
    try {
      const data = profileForm.getValues();
      await updateUserProfile.mutateAsync(data);
      await getAccessTokenSilently({ cacheMode: 'off' });

      window.location.replace(window.location.href);
    } catch (error) {
      //@TODO: Handle error parse + show validation errors
      const errors = (error as any).response.data.errors;
    }
  }

  return (
    <PageWrapper className="flex flex-col">
      <header className="mb-16 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">{t('title')}</h1>
        </div>
      </header>

      {isFetching ? (
        <LoadingSkeleton />
      ) : (
        <div className="flex gap-4">
          <section className="flex min-w-[400px] flex-col gap-4">
            <h2 className="text-xl font-medium text-slate-900">
              {t('contactInfo.title')}
            </h2>
            <Form {...contactForm}>
              <form className="flex flex-col">
                <FormField
                  control={contactForm.control}
                  name="firstName"
                  disabled
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                        {t('labels.firstName')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field}></Input>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={contactForm.control}
                  name="lastName"
                  disabled
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                        {t('labels.lastName')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field}></Input>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={contactForm.control}
                  name="dateOfBirth"
                  disabled
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                        {t('labels.dateOfBirth')}
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={new LocalDate({
                            timestamp: field?.value || '',
                            locale,
                          }).format(DateTime.DATE_SHORT)}
                        ></Input>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={contactForm.control}
                  name="phoneNumber"
                  disabled
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                        {t('labels.phoneNumber')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field}></Input>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={contactForm.control}
                  name="email"
                  disabled
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                        {t('labels.email')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field}></Input>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </form>
            </Form>
          </section>
          <div className="mx-4 border-l-2 border-slate-200"></div>
          <section className="flex min-w-[400px] flex-col gap-4">
            <h2 className="text-xl font-medium text-slate-900">
              {t('preferences.title')}
            </h2>
            <Form {...profileForm}>
              <form
                onSubmit={profileForm.handleSubmit(onSubmit)}
                className="flex flex-col"
              >
                <FormField
                  control={profileForm.control}
                  name="language"
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                        {t('labels.language')}
                      </FormLabel>
                      <FormControl>
                        <Combobox<ComboboxItem>
                          items={languages}
                          emptyLabel={t('labels.empty')}
                          placeholder={t('labels.placeholder')}
                          selectedItem={languages.find(
                            (language) => language.value === field.value,
                          )}
                          onSelect={(language) => {
                            field.onChange(language?.value);
                          }}
                          disableSearch
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={profileForm.control}
                  name="preferredCurrency"
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                        {t('labels.currency')}
                      </FormLabel>
                      <FormControl>
                        <CurrencyPicker
                          value={field.value}
                          onChange={(currency) =>
                            field.onChange(currency?.value)
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={profileForm.control}
                  name="tz"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                        {t('labels.tz')}
                      </FormLabel>
                      <FormControl>
                        <TzPicker
                          value={field.value}
                          onChange={(tz) => field.onChange(tz?.value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="mt-2 text-xs text-slate-400">
                  {t('labels.userTime')}:&nbsp;
                  {new LocalDate({
                    timestamp: new Date().toISOString(),
                    locale,
                    tz,
                  }).format()}
                </div>
                <div className="mt-1 text-xs text-slate-400">
                  UTC:&nbsp;
                  {new LocalDate({
                    timestamp: new Date().toISOString(),
                    locale,
                    tz: 'utc',
                  }).format()}
                </div>
                <Button type="submit" className="right max-w-fit self-end">
                  {t('actions.save', { ns: 'common' })}
                </Button>
              </form>
            </Form>
          </section>
        </div>
      )}
    </PageWrapper>
  );
}

export default SettingsPage;
