import { flexRender, Table as TanStackTable } from '@tanstack/react-table';

import { cn } from '@agyt/client/shared/util/theme';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './table';
import { Button } from './button';
import { useTranslation } from 'react-i18next';

export function DataTable<T>({
  table,
  onRowClick,
  cellClassName = '',
  hasPagination = false,
  pageCount,
  currentPage,
  nextDisabled,
  previousDisabled,
  previousPage,
  nextPage,
}: {
  table: TanStackTable<T>;
  onRowClick: (Row: T) => void;
  cellClassName?: string;
  hasPagination?: boolean;
  pageCount?: number;
  currentPage?: number;
  nextDisabled?: boolean;
  previousDisabled?: boolean;
  previousPage?: () => void;
  nextPage?: () => void;
}) {
  const { t } = useTranslation('common');
  const columns = table
    .getHeaderGroups()[0]
    .headers.map((header) => header.column.columnDef);

  return (
    <>
      <section className="mt-5 rounded-md border border-slate-300 bg-white">
        <Table className="overflow-x-auto rounded-md bg-white leading-6 [&_th]:text-slate-400">
          <TableHeader className="rounded-md">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="hover:bg-white">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="hover:rounded-md">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="[&_tr]:text-slate-900">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className="cursor-pointer"
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRowClick(row.original);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className={cn(cellClassName)}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </section>
      {hasPagination && previousPage && nextPage && (
        <div className="mt-2 flex items-center justify-end gap-2">
          <Button
            size="sm"
            variant="outline"
            onClick={() => previousPage()}
            disabled={previousDisabled}
          >
            {t('previous')}
          </Button>
          <Button
            size="sm"
            variant="outline"
            onClick={() => nextPage()}
            disabled={nextDisabled}
          >
            {t('next')}
          </Button>
          <span className="flex items-center gap-1">
            <p className="text-sm text-slate-500">
              {t('page')} {currentPage || '1'} {t('of')} {pageCount}
            </p>
          </span>
        </div>
      )}
    </>
  );
}

export default DataTable;
