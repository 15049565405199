import * as React from 'react';
import { cn } from '@agyt/client/shared/util/theme';
import { Label } from './label';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-slate-300 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-400 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid=true]:border-2 aria-[invalid=true]:border-red-500',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export interface InputWithIconProps extends InputProps {
  children: React.ReactNode;
}

export const InputWithIcon = React.forwardRef<
  HTMLInputElement,
  InputWithIconProps
>(({ className, type, children, ...props }, ref) => {
  return (
    <div className="relative w-full">
      <Input
        ref={ref}
        type={type}
        className={cn('pl-10', className)}
        {...props}
      />
      <span className="absolute left-0 top-0 m-2 h-6 w-6">{children}</span>
    </div>
  );
});

export interface InputWithLabelProps extends InputProps {
  label: string;
  id: string;
}

export const InputWithLabel = React.forwardRef<
  HTMLInputElement,
  InputWithLabelProps
>(({ className, label, id, ...props }, ref) => {
  return (
    <div className={cn('grid w-full items-center gap-1.5', className)}>
      <Label htmlFor={id}>{label}</Label>
      <Input id={id} {...props} />
    </div>
  );
});

export interface InputWithSuffixProps extends InputProps {
  suffix: string;
}
export const InputWithSuffix = React.forwardRef<
  HTMLInputElement,
  InputWithSuffixProps
>(({ suffix, className, type, ...props }, ref) => {
  return (
    <div
      className="input-w-suffix relative w-full after:absolute after:right-0 after:top-0 after:h-full after:px-3 after:py-2 after:text-slate-400"
      data-suffix={suffix}
    >
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-slate-300 bg-white py-2 pl-3 pr-14 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-400 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid=true]:border-2 aria-[invalid=true]:border-red-500',
          className,
        )}
        ref={ref}
        {...props}
      />
    </div>
  );
});
