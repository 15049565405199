import { createRoute } from '@tanstack/react-router';
import { indexRoute } from '@agyt/client/web/feature/common';
import SettingsPage from './settings-page';

export const settingsRoot = createRoute({
  getParentRoute: () => indexRoute,
  path: '/settings',
});

const settingsIndexRoute = createRoute({
  getParentRoute: () => settingsRoot,
  path: '/',
  component: SettingsPage,
});

export const settingsRoutes = settingsRoot.addChildren([settingsIndexRoute]);
