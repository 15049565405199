import { useHttpClient } from '@agyt/client/web/core/http';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { SupportedCurrency } from '@agyt/shared/util/common';
import {
  BeneficiaryGroupResponse,
  BeneficiaryResponse,
  PaymentPurposeCodesCriteriaQuery,
  PaymentPurposeCodesResponse,
  SuccessApiResponse,
} from '@agyt/shared/types';

export type BeneficiaryDetails = {
  currency: SupportedCurrency;
  bankAccountCountry: string;
  beneficiaryCountry: string;
};

export type BeneficiaryRequirements = {
  payment_type: 'priority' | 'regular';
  beneficiary_entity_type: 'individual' | 'company';
  [key: string]: string; // field name and regex pairs we get from CC
};

export type GetPaymentPurposeCodesParams = {
  country: string;
  currency: SupportedCurrency;
  entityType?: string;
};

export type PurposeCode = {
  bank_account_country: string;
  currency: SupportedCurrency;
  entity_type: 'individual' | 'company';
  purpose_code: string;
  purpose_description: string;
};

export function useBeneficiaryRequirements({
  currency,
  bankAccountCountry,
  beneficiaryCountry,
}: BeneficiaryDetails) {
  const { httpClient } = useHttpClient();
  return useQuery<{ data: BeneficiaryRequirements[] }>({
    enabled: !!currency && !!bankAccountCountry && !!beneficiaryCountry,
    queryKey: [
      'beneficiaries:requirements',
      currency,
      bankAccountCountry,
      beneficiaryCountry,
    ],
    async queryFn() {
      const res = await httpClient.get('/beneficiaries/requirements', {
        params: {
          currency,
          bankAccountCountry,
          beneficiaryCountry,
        },
      });
      return res?.data;
    },
    placeholderData: keepPreviousData,
  });
}

export function useFindBeneficiaries() {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: ['beneficiaries'],
    async queryFn() {
      const res = await httpClient.get('/beneficiaries/find');
      return res?.data as SuccessApiResponse<BeneficiaryGroupResponse[]>;
    },
    placeholderData: keepPreviousData,
  });
}

export function useGetBeneficiaryAccounts(
  id?: string,
  forTransactionDetails = false,
  invalidateCacheAfterSeconds = 30,
) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: ['beneficiaries:accounts', id],
    async queryFn() {
      const res = await httpClient.get(`/beneficiaries/${id}`, {
        params: { forTransactionDetails },
      });
      return res?.data as SuccessApiResponse<BeneficiaryResponse[]>;
    },
    enabled: !!id,
    staleTime: invalidateCacheAfterSeconds * 1000,
    placeholderData: keepPreviousData,
  });
}

export function useGetPaymentPurposeCodes(
  criteria: Partial<PaymentPurposeCodesCriteriaQuery>,
) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: ['beneficiaries:payment-purpose-codes', criteria],
    async queryFn() {
      const res = await httpClient.get('/beneficiaries/payment-purpose-codes', {
        params: criteria,
      });
      return res?.data as SuccessApiResponse<PaymentPurposeCodesResponse[]>;
    },
  });
}

export function useValidateBeneficiary() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async (
      data: any, // @TODO input type
    ): Promise<SuccessApiResponse<BeneficiaryResponse>> => {
      try {
        const res = await httpClient.post('/beneficiaries/validate', data);

        return res?.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'validateBeneficiary',
    },
  });
}

export function useCreateBeneficiary() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async (data: any) => {
      try {
        const res = await httpClient.post('/beneficiaries', data);
        return res?.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'createBeneficiary',
    },
  });
}

export function useGetAllBeneficiaries() {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: ['beneficiaries:all'],
    async queryFn(): Promise<SuccessApiResponse<BeneficiaryGroupResponse[]>> {
      const res = await httpClient.get('/beneficiaries');
      return res?.data;
    },
    placeholderData: keepPreviousData,
  });
}

export function useDeleteBeneficiary() {
  const { httpClient } = useHttpClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: any) => {
      try {
        await httpClient.delete(`/beneficiaries/${data.id}`);
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    onSuccess() {
      return queryClient.invalidateQueries({ queryKey: ['beneficiaries:all'] });
    },
    scope: {
      id: 'deleteBeneficiary',
    },
  });
}
