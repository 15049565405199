import { lazy, Suspense } from 'react';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import React from 'react';

export type RootRouteContext = {
  auth: {
    isLoading: boolean;
    isAuthenticated: boolean;
  };
};

export const rootRoute = createRootRouteWithContext<RootRouteContext>()({
  component: () => (
    <>
      <Outlet />
      <TanStackRouterDevtools />
    </>
  ),
});

const LazyDevtools = lazy(() =>
  // Lazy load in development
  import('@tanstack/router-devtools').then((res) => ({
    default: res.TanStackRouterDevtools,
    // For Embedded Mode
    // default: res.TanStackRouterDevtoolsPanel
  })),
);

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : () => {
        return (
          <Suspense>
            <LazyDevtools />
          </Suspense>
        );
      };
