import { useAuth0 } from '@auth0/auth0-react';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import {
  BalanceCard,
  Button,
  NavigationItem,
  Skeleton,
} from '@agyt/client/shared/ui/components';
import {
  IconArrowLeftRight,
  IconCreditCard,
  IconLogout,
  IconPieChart,
  IconSettings,
  IconUsers,
  Logo,
} from '@agyt/client/shared/ui/icons';
import { useUser } from '@agyt/client/web/core/user';
import { useBalances } from '@agyt/client/web/data-access/api';
import { LocalDate, Money } from '@agyt/shared/util/common';

const BalanceCardSkeleton = () => (
  <div className="mb-8 flex flex-col gap-1 rounded-md border border-slate-200 p-4 2xl:mb-[60px]">
    <Skeleton className="h-8 w-40 rounded-full" />
    <Skeleton className="h-5 w-32 rounded-full" />
    <Skeleton className="h-4 w-28 rounded-full" />
  </div>
);

export function Navigation() {
  const { t } = useTranslation('navigation');
  const { logout } = useAuth0();
  const { locale, tz } = useUser();
  const { isPending, isError, data: balancesData, error } = useBalances();

  const data = balancesData?.data;
  const hasOverallData = data && data.overall;

  const getBalanceTotal = () => {
    if (hasOverallData) {
      return new Money({
        amount: data?.overall?.amount,
        currency: data?.overall?.currency,
        locale,
      }).format();
    }
    return '';
  };

  return (
    <div className="flex w-[296px] min-w-[296px] flex-col overflow-auto border-r border-slate-200 bg-white p-10">
      <div className="mb-8 2xl:mb-[100px]">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      {isPending ? (
        <BalanceCardSkeleton />
      ) : isError ? (
        <p>{error.message}</p>
      ) : (
        hasOverallData && (
          <BalanceCard
            total={getBalanceTotal()}
            date={new LocalDate({
              timestamp: data?.overall?.timestamp,
              locale,
              tz,
            }).format()}
            className="mb-8 2xl:mb-[60px]"
          />
        )
      )}
      <nav className="mb-8 text-slate-500 2xl:mb-16">
        <ul>
          <li>
            <Link to="/" className="group">
              <NavigationItem
                title={t('labels.dashboard')}
                icon={<IconPieChart />}
              />
            </Link>
            <Link to="/account-currencies" className="group">
              <NavigationItem
                title={t('labels.currencies')}
                icon={<IconCreditCard />}
              />
            </Link>
            <Link to="/beneficiaries" className="group">
              <NavigationItem
                title={t('labels.beneficiaries')}
                icon={<IconUsers />}
              />
            </Link>
            <Link to="/transactions" className="group">
              <NavigationItem
                title={t('labels.transactions')}
                icon={<IconArrowLeftRight />}
              />
            </Link>
          </li>
        </ul>
      </nav>
      <div className="mb-4 flex flex-col justify-between gap-2">
        <Link to="/currency-exchange">
          <NavigationItem
            title={t('actions.exchange', { ns: 'common' })}
            className="justify-center bg-lime-100 text-lime-800 hover:bg-lime-200"
          />
        </Link>
        <Link to="/pay">
          <NavigationItem
            title={t('actions.pay', { ns: 'common' })}
            className="justify-center bg-lime-100 text-lime-800 hover:bg-lime-200"
          />
        </Link>
        {/* <Link to="/">
          <NavigationItem
            title={t('actions.upload', { ns: 'common' })}
            className="justify-center bg-lime-100 text-lime-800 hover:bg-lime-200"
          />
        </Link> */}
      </div>
      <div className="flex flex-1 flex-col justify-end">
        <Link to="/settings" className="group">
          <NavigationItem
            title={t('labels.settings')}
            icon={<IconSettings />}
          />
        </Link>
        <Button
          variant="nav"
          size="nav"
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          <NavigationItem title={t('labels.logout')} icon={<IconLogout />} />
        </Button>
      </div>
    </div>
  );
}

export default Navigation;
