// The error codes are snake_cased because we map beneficiary requirements directly to form fields
export const BENEFICIARY_VALIDATION_ERROR_FIELD_MAP: { [key: string]: string } =
  {
    beneficiary_country_is_in_invalid_format: 'beneficiary_country',
    beneficiary_entity_type_not_in_range: 'beneficiary_entity_type',
    beneficiary_entity_type_is_missing: 'beneficiary_entity_type',
    beneficiary_company_name_is_missing: 'beneficiary_company_name',
    company_name_cannot_be_only_digits: 'beneficiary_company_name',
    beneficiary_first_name_is_missing: 'beneficiary_first_name',
    beneficiary_last_name_is_missing: 'beneficiary_last_name',
    individual_name_cannot_contain_digits: 'beneficiary_first_name',
    beneficiary_state_or_province_is_missing: 'beneficiary_state_or_province',
    beneficiary_state_or_province_is_not_supported:
      'beneficiary_state_or_province',
    beneficiary_postcode_is_missing: 'beneficiary_postcode',
    beneficiary_date_of_birth_type_is_wrong: 'beneficiary_date_of_birth',
    beneficiary_date_of_birth_is_earlier: 'beneficiary_date_of_birth',
    beneficiary_date_of_birth_is_after: 'beneficiary_date_of_birth',
    account_number_is_too_short: 'account_number',
    account_number_is_too_long: 'account_number',
    account_number_is_missing: 'account_number',
    account_number_is_invalid: 'account_number',
    routing_code_value_1_is_too_short: 'routing_code_value_1',
    routing_code_value_1_is_too_long: 'routing_code_value_1',
    routing_code_value_2_is_too_short: 'routing_code_value_2',
    routing_code_value_2_is_too_long: 'routing_code_value_2',
    iban_is_too_short: 'iban',
    iban_is_too_long: 'iban',
    iban_is_missing: 'iban',
    iban_is_invalid: 'iban',
    bic_swift_is_missing: 'bic_swift',
    bic_swift_is_invalid: 'bic_swift',
    bank_address_is_missing: 'bank_address',
    bank_address_is_too_short: 'bank_address',
    bank_address_is_too_long: 'bank_address',
    bank_name_is_too_short: 'bank_name',
    bank_name_is_too_long: 'bank_name',
    bank_name_is_missing: 'bank_name',
    bank_account_type_not_in_range: 'bank_account_type',
    bank_account_type_is_missing: 'bank_account_type',
    routing_code_type_1_not_in_range: 'routing_code_type_1',
    routing_code_type_2_not_in_range: 'routing_code_type_2',
    bank_country_is_required: 'bank_country',
    bank_country_is_in_invalid_format: 'bank_country',
    bank_country_is_not_supported: 'bank_country',
    currency_is_required: 'currency',
    currency_is_in_invalid_format: 'currency',
    currency_code_is_invalid: 'currency',
    unique_value_parameters: 'routing_code_type_1',
    beneficiary_create_failed: 'beneficiary',
    beneficiary_details_are_missing: 'beneficiary_company_name',
    payment_types_not_in_range: 'payment_types',
    sort_code_is_missing: 'sort_code',
    sort_code_is_invalid: 'sort_code',
    aba_is_missing: 'aba',
    aba_is_invalid: 'aba',
    bsb_code_is_missing: 'bsb_code',
    bsb_code_is_invalid: 'bsb_code',
    branch_code_is_missing: 'branch_code',
    branch_code_is_invalid: 'branch_code',
    institution_no_is_missing: 'institution_no',
    institution_no_is_invalid: 'institution_no',
    bank_code_is_missing: 'bank_code',
    bank_code_is_invalid: 'bank_code',
    beneficiary_update_failed: 'beneficiary',
    on_behalf_of_self: 'contact',
    contact_not_found: 'contact',
  };
