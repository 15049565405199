import { createRoute } from '@tanstack/react-router';
import CurrencyExchangePage from './currency-exchange-page';
import { indexRoute } from '@agyt/client/web/feature/common';

const currencyExchangeRoot = createRoute({
  getParentRoute: () => indexRoute,
  path: '/currency-exchange',
});

const currencyExchangeIndexRoute = createRoute({
  getParentRoute: () => currencyExchangeRoot,
  path: '/',
  component: CurrencyExchangePage,
});

export const currencyExchangeRoutes = currencyExchangeRoot.addChildren([
  currencyExchangeIndexRoute,
]);
