import React from 'react';

import { cn } from '@agyt/client/shared/util/theme';

export interface NavigationItemProps {
  title: string;
  icon?: React.ReactNode;
  className?: string;
}

export function NavigationItem({
  icon,
  title,
  className,
}: NavigationItemProps) {
  return (
    <div
      className={cn(
        'flex w-full items-center gap-2 rounded-sm px-2 py-1.5 text-slate-500 hover:bg-slate-100 hover:text-slate-900 group-[.active]:bg-slate-100 group-[.active]:text-slate-900',
        className,
      )}
    >
      {icon}
      <span className="text-sm font-medium">{title}</span>
    </div>
  );
}

export default NavigationItem;
