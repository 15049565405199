import { useNumberFormat } from '@react-input/number-format';
import { forwardRef, useState } from 'react';

import { useUser } from '@agyt/client/web/core/user';
import { InputWithSuffix } from './input';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const AmountInput = forwardRef<
  HTMLInputElement,
  { suffix: string; className?: string; [key: string]: any }
>(({ suffix, className, ...props }, ref) => {
  const { locale } = useUser();
  const [value, setValue] = useState<{ value: string; number: number }>({
    value: props.value ?? '',
    number: 0,
  });

  ref = useNumberFormat({
    locales: locale,
    maximumFractionDigits: 2,
    onNumberFormat: (e: CustomEvent<{ value: string; number: number }>) => {
      setValue(e.detail);
      props.onChange({
        target: {
          value: e.detail.number > 0 ? e.detail.number.toFixed(2) : undefined,
        },
      });
    },
  });

  return (
    <InputWithSuffix
      {...props}
      ref={ref}
      suffix={suffix}
      value={value?.value}
      className={className}
      onChange={noop}
    />
  );
});

export default AmountInput;
