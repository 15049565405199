import { useHttpClient } from '@agyt/client/web/core/http';
import {
  SuccessApiResponse,
  UserDataFullResponse,
  UserProfilePayloadBody,
  UserProfileResponse,
} from '@agyt/shared/types';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { BALANCES_CACHE_KEY } from './dashboard';

export const USER_PROFILE_CACHE_KEY = 'user-profile';

export function useGetUserProfile() {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: [USER_PROFILE_CACHE_KEY],
    async queryFn() {
      const res = await httpClient.get('/me');
      return res?.data as SuccessApiResponse<UserDataFullResponse>;
    },
    enabled: false,
    placeholderData: keepPreviousData,
  });
}

export function useUpdateUserProfile() {
  const { httpClient } = useHttpClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: UserProfilePayloadBody) => {
      try {
        const res = await httpClient.patch('/me', data);
        return res.data as SuccessApiResponse<UserProfileResponse>;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'updateUserProfile',
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [USER_PROFILE_CACHE_KEY],
      });
      // We need to invalidate this so dashboard and navigation balance cards are refetched
      queryClient.invalidateQueries({
        queryKey: [BALANCES_CACHE_KEY],
      });
    },
  });
}
