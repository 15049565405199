import { cn } from '@agyt/client/shared/util/theme';
import { useTranslation } from 'react-i18next';

export interface BalanceCardProps {
  total: string;
  date: string;
  className?: string;
}

export function BalanceCard({ total, date, className }: BalanceCardProps) {
  const { t } = useTranslation('common');

  return (
    <div
      className={cn(
        'flex flex-col gap-1 rounded-md border border-slate-200 p-4',
        className,
      )}
    >
      <div className="text-xl font-medium text-slate-900">
        <span>{total}</span>
      </div>
      <div className="text-sm text-slate-600">
        {t('balance.overallBalance')}
      </div>
      <div className="text-xs leading-5 text-slate-500">
        <span>{t('balance.asOf')}</span>&nbsp;
        <span>{date}</span>
      </div>
    </div>
  );
}

export default BalanceCard;
