import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';

import { SupportedCurrency } from '@agyt/shared/util/common';
import { Button } from '@agyt/client/shared/ui/components';

export function AccountListItem({
  name,
  balance,
  children,
  currency,
  returnTo,
}: {
  name: string;
  balance: string;
  currency: SupportedCurrency;
  children: React.ReactNode;
  returnTo: string;
}) {
  const { t } = useTranslation('common');
  return (
    <div className="flex h-[77px] rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <div className="mr-5">{children}</div>
          <Link to="/transactions" search={{ currency }}>
            <div className="mr-10 text-base leading-7 text-slate-500">
              {name}
            </div>
          </Link>
          <div className="text-xl font-medium text-slate-900">{balance}</div>
        </div>

        <div className="flex items-center gap-3">
          <Button variant="subtle" size="sm" asChild>
            <Link to={`/transactions/statements/${currency}`}>
              {t('buttons.downloadStatement', { ns: 'transactions' })}
            </Link>
          </Button>
          <Button variant="link" size="sm" asChild>
            <Link to="/account-details" search={{ currency, returnTo }}>
              {t('actions.account_details')}
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AccountListItem;
