import { useMask } from '@react-input/mask';
import { Input } from './input';

export function IbanInput({ className }: { className?: string }) {
  const inputRef = useMask({
    mask: Array.from({ length: 9 }, (_, i) => (i === 8 ? '__' : '____')).join(
      ' ',
    ),
    replacement: { _: /\d/ },
  });

  return <Input ref={inputRef} className={className} />;
}

export default IbanInput;
