import { createRoute, Link } from '@tanstack/react-router';
import React from 'react';
import { indexRoute } from '@agyt/client/web/feature/common';
import {
  Badge,
  Button,
  GradientCircle,
  PageWrapper,
  Skeleton,
} from '@agyt/client/shared/ui/components';
import {
  IconAlertCircle,
  IconArrowUpDown,
  IconArrowUpRight,
  IconCheckCircle,
  IconClock,
} from '@agyt/client/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { LocalDate, Money } from '@agyt/shared/util/common';
import {
  useBalances,
  useFindTransactions,
} from '@agyt/client/web/data-access/api';
import { useUser } from '@agyt/client/web/core/user';
import { CcTransactionStatus } from '@agyt/shared/types';
import { AccountCard } from '@agyt/client/web/ui/components';

export const dashboardRoute = createRoute({
  getParentRoute: () => indexRoute,
  path: '/',
  component: DashboardPage,
});

function BalanceCardBackground() {
  return (
    <svg
      width="584"
      height="178"
      viewBox="0 0 584 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M238.877 247.832C238.877 103.63 94.0404 -2.57913 0 -2.57913V-157C116.245 -157 220.589 -78.8537 291.906 45.0484C363.223 -78.8537 467.566 -157 583.812 -157V-2.57913C489.771 -2.57913 346.956 103.63 346.956 247.832H238.877Z"
        fill="url(#paint0_linear_258_39743)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_258_39743"
          x1="291.906"
          y1="-157"
          x2="291.906"
          y2="247.832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4C5CB" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function AccountCardSkeleton() {
  return (
    <div className="flex h-[179px] min-w-[363px] rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex h-full flex-col">
        <Skeleton className="mb-4 h-7 w-32 rounded-full" />
        <Skeleton className="mb-4 h-7 w-40 rounded-full" />
        <div className="flex flex-1 items-end gap-3">
          <Skeleton className="mb-4 h-7 w-32 rounded-full" />
        </div>
      </div>
      <div className="flex flex-1 items-start justify-end">
        <Skeleton className="h-11 w-11 rounded-full" />
      </div>
    </div>
  );
}

function TransactionCard({
  label,
  count,
  children,
  status,
  isFetching,
}: {
  label: string;
  count: number;
  children: React.ReactNode;
  status: CcTransactionStatus;
  isFetching?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div className="flex h-[77px] w-[363px] items-center rounded-lg border border-slate-200 bg-white p-4">
      {isFetching ? (
        <Skeleton className="h-6 w-72 rounded-full" />
      ) : (
        <>
          <div className="mr-3.5">{children}</div>
          <span className="text-base font-medium text-slate-900">{label}</span>
          <span className="ml-2 text-base leading-7 text-slate-500">
            {count}
          </span>
          <span className="flex-1"></span>
          <Link to="/transactions" search={{ status }}>
            <Button variant={'subtle'}>
              {t('actions.seeAll', { ns: 'common' })}
            </Button>
          </Link>
        </>
      )}
    </div>
  );
}

function DashboardPage() {
  const { t } = useTranslation('dashboard');
  const { locale, tz } = useUser();
  const now = new Date();
  const startOfDay = new LocalDate({
    timestamp: new Date(now.setHours(0, 0, 0, 0)).toISOString(),
    locale,
  }).toISO();
  const endOfDay = new LocalDate({
    timestamp: new Date(now.setHours(23, 59, 59, 999)).toISOString(),
    locale,
  }).toISO();

  const { isPending, isError, data: balancesData, error } = useBalances();
  const { data: completedData, isFetching: fetchingCompleted } =
    useFindTransactions({
      status: 'completed',
      createdAtFrom: startOfDay,
      createdAtTo: endOfDay,
    });
  const { data: pendingData, isFetching: fetchingPending } =
    useFindTransactions({
      status: 'pending',
      createdAtFrom: startOfDay,
      createdAtTo: endOfDay,
    });
  const { data: deletedData, isFetching: fetchingDeleted } =
    useFindTransactions({
      status: 'deleted',
      createdAtFrom: startOfDay,
      createdAtTo: endOfDay,
    });
  const data = balancesData?.data;

  return (
    <PageWrapper className="flex flex-col">
      <div className="relative flex h-[178px] w-full items-center justify-between rounded-2xl border border-slate-200 bg-white px-10">
        <div className="absolute right-14">
          <BalanceCardBackground />
        </div>
        <div className="z-10 flex flex-col gap-4">
          <div className="text-3xl font-medium text-slate-900">
            {isPending ? (
              <Skeleton className="h-9 w-56 rounded-full" />
            ) : (
              data &&
              data.overall &&
              new Money({
                amount: data?.overall?.amount,
                currency: data?.overall?.currency,
                locale,
              }).format()
            )}
          </div>
          <div className="text-slate-500">
            {isPending ? (
              <Skeleton className="h-6 w-72 rounded-full" />
            ) : (
              <>
                <span className="mr-3.5 text-xl leading-5">
                  {t('balance.overallBalance', { ns: 'common' })}
                </span>
                <span className="text-xs leading-5">
                  <span>{t('balance.asOf', { ns: 'common' })}</span>&nbsp;
                  <span>
                    {data?.overall?.timestamp &&
                      new LocalDate({
                        timestamp: data?.overall?.timestamp,
                        locale,
                        tz,
                      }).format()}
                  </span>
                </span>
              </>
            )}
          </div>
        </div>
        <div className="z-10 flex gap-4">
          <Button asChild>
            <Link to="/pay">
              <IconArrowUpRight />
              <span className="ml-2">{t('actions.pay', { ns: 'common' })}</span>
            </Link>
          </Button>
          <Button asChild>
            <Link to="/currency-exchange">
              <IconArrowUpDown />
              <span className="ml-2">
                {t('actions.exchange', { ns: 'common' })}
              </span>
            </Link>
          </Button>
        </div>
      </div>
      <section className="mt-10">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <h2 className="text-xl font-medium text-slate-900">
              {t('account_currencies')}
            </h2>
            {data?.balances?.length && (
              <Badge className="h-6 rounded-sm py-0.5">
                {data?.balances?.length}
              </Badge>
            )}
          </div>
          <Button variant="link" asChild>
            <Link to="/account-currencies">
              {t('actions.seeAll', { ns: 'common' })}
            </Link>
          </Button>
        </div>
        <div className="mt-4 flex flex-wrap gap-6">
          {isPending ? (
            <AccountCardSkeleton />
          ) : isError ? (
            <p>{error.message}</p>
          ) : (
            data?.balances?.map((balance: any) => (
              <AccountCard
                key={balance.id}
                name={`${balance.currency} ${t('account')}`}
                balance={new Money({
                  amount: balance.amount,
                  currency: balance.currency,
                  locale,
                }).format()}
                currency={balance.currency}
                returnTo="dashboard"
              />
            ))
          )}
        </div>
      </section>

      <section className="mt-11">
        <div className="flex justify-between">
          <h2 className="text-xl font-medium text-slate-900">
            {t('transactions')}
          </h2>
          <Button variant="link" asChild>
            <Link to="/transactions">
              {t('actions.seeAll', { ns: 'common' })}
            </Link>
          </Button>
        </div>
        <div className="mt-4 flex flex-wrap gap-6">
          <TransactionCard
            label={t('status.completed', { ns: 'common' })}
            count={completedData?.pagination?.totalEntries || 0}
            isFetching={fetchingCompleted}
            status="completed"
          >
            <GradientCircle>
              <IconCheckCircle />
            </GradientCircle>
          </TransactionCard>

          <TransactionCard
            label={t('status.pending', { ns: 'common' })}
            count={pendingData?.pagination?.totalEntries || 0}
            isFetching={fetchingPending}
            status="pending"
          >
            <GradientCircle variant="orange">
              <IconClock />
            </GradientCircle>
          </TransactionCard>

          <TransactionCard
            label={t('status.failed', { ns: 'common' })}
            count={deletedData?.pagination?.totalEntries || 0}
            isFetching={fetchingDeleted}
            status="deleted"
          >
            <GradientCircle variant="slate">
              <IconAlertCircle />
            </GradientCircle>
          </TransactionCard>
        </div>
      </section>
    </PageWrapper>
  );
}
