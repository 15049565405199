import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { useHttpClient } from '@agyt/client/web/core/http';
import {
  ConversionCancellationQuoteResponse,
  ConversionCancellationResponse,
  ConversionDatesCriteria,
  ConversionDatesResponse,
  ConversionResponse,
  CreateConversionPayloadBody,
  DetailedRatesCriteriaQuery,
  DetailedRatesFullResponse,
  IdCriteria,
  SuccessApiResponse,
  TransactionCriteria,
  TransactionResponse,
} from '@agyt/shared/types';
import { TRANSACTIONS_CACHE_KEY } from './transactions';

export const CONVERSIONS_CACHE_KEY = 'conversions';

export function useGetFxRates() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async (
      criteria: DetailedRatesCriteriaQuery,
    ): Promise<SuccessApiResponse<DetailedRatesFullResponse>> => {
      try {
        const res = await httpClient.get('/fx/rates', { params: criteria });
        return res?.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'getFxRates',
    },
  });
}

export function useGetFxConversionDates() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async (
      criteria: ConversionDatesCriteria,
    ): Promise<SuccessApiResponse<ConversionDatesResponse>> => {
      try {
        const res = await httpClient.get('/fx/dates', { params: criteria });
        return res?.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'getFxDates',
    },
  });
}

export function useCreateConversion() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async (
      data: CreateConversionPayloadBody,
    ): Promise<SuccessApiResponse<ConversionResponse>> => {
      try {
        const res = await httpClient.post('/fx', data);
        return res?.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'createConversion',
    },
  });
}

export function useGetConversion({ id }: { id?: string }) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: [CONVERSIONS_CACHE_KEY, id],
    async queryFn() {
      const res = await httpClient.get(`/fx/${id}`);
      return res?.data as SuccessApiResponse<ConversionResponse>;
    },
    enabled: !!id,
    placeholderData: keepPreviousData,
  });
}

export function useGetConversionCancellationQuote({
  id,
  pullConversionCancellationQuote,
}: {
  id?: string;
  pullConversionCancellationQuote: boolean;
}) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: [CONVERSIONS_CACHE_KEY, id, 'cancellation-quote'],
    async queryFn() {
      if (!id) {
        return;
      }
      const res = await httpClient.get(`/fx/${id}/cancellation-quote`);
      return res?.data as SuccessApiResponse<ConversionCancellationQuoteResponse>;
    },
    enabled: !!id && pullConversionCancellationQuote,
    placeholderData: keepPreviousData,
  });
}

export function useCancelConversion({
  transaction,
  filters,
}: {
  transaction?: TransactionResponse;
  filters?: Partial<TransactionCriteria>;
}) {
  const queryClient = useQueryClient();
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async ({
      id,
    }: IdCriteria): Promise<
      SuccessApiResponse<ConversionCancellationResponse>
    > => {
      try {
        const res = await httpClient.delete(`/fx/${id}`);
        return res?.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [CONVERSIONS_CACHE_KEY, res.data?.conversionId],
      });
      if (transaction) {
        queryClient.invalidateQueries({
          queryKey: [TRANSACTIONS_CACHE_KEY, transaction.id],
        });
        if (filters) {
          queryClient.invalidateQueries({
            queryKey: [TRANSACTIONS_CACHE_KEY, filters],
          });
        }
      }
    },
    scope: {
      id: 'cancelConversion',
    },
  });
}
