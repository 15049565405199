import { Link } from '@tanstack/react-router';

import { cn } from '@agyt/client/shared/util/theme';

export type DetailsCardData = {
  key: string;
  value: string;
  href?: string;
};

export function DetailsCard({ data }: { data: DetailsCardData[] }) {
  return (
    <div className="mt-4 rounded-lg border border-slate-200 bg-white px-4 py-2">
      <ul>
        {data.map((row, index: number) => (
          <li
            className={cn('flex w-full items-center justify-between py-2', {
              'border-b border-slate-200': index !== data.length - 1,
            })}
            key={row.key}
          >
            <span className="text-sm font-medium leading-6 text-slate-400">
              {row.key}
            </span>
            {row?.href && (
              <Link to={row.href} className="cursor-pointer hover:underline">
                <span className="text-sm font-medium leading-6 text-slate-900">
                  {row.value}
                </span>
              </Link>
            )}
            {!row?.href && (
              <span className="text-sm font-medium leading-6 text-slate-900">
                {row.value}
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
