import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  supportedCurrencies,
  SupportedCurrency,
} from '@agyt/shared/util/common';
import i18next from 'i18next';
import { useAuth0 } from '@auth0/auth0-react';

type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0 - Sunday, 1 - Monday, ..., 6 - Saturday

interface UserProvider {
  email?: string;
  locale: string;
  firstDayOfWeek: DayOfWeek; // 0 indexed day of the week 0-6
  primaryCurrency: SupportedCurrency;
  tz: string;
}

const CUSTOM_CLAIM_NAMESPACE = 'https://agyt.io';
const DEFAULT_CURRENCY = 'EUR';
const DEFAULT_LANGUAGE = 'en';

export const UserContext = createContext<UserProvider | undefined>(undefined);

export function UserProvider({ children }: PropsWithChildren) {
  const { user, isAuthenticated } = useAuth0();

  const [userInfo, setUserInfo] = useState<UserProvider>({
    locale: navigator.language ?? DEFAULT_LANGUAGE,
    firstDayOfWeek: 1,
    primaryCurrency: DEFAULT_CURRENCY,
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  useEffect(() => {
    if (isAuthenticated && user) {
      const language =
        user[`${CUSTOM_CLAIM_NAMESPACE}/language`] || DEFAULT_LANGUAGE;
      const locale = new Intl.Locale(language) as any;
      const primaryCurrency =
        (user[
          `${CUSTOM_CLAIM_NAMESPACE}/preferredCurrency`
        ] as SupportedCurrency) || DEFAULT_CURRENCY;
      const tz =
        user[`${CUSTOM_CLAIM_NAMESPACE}/tz`] ||
        Intl.DateTimeFormat().resolvedOptions().timeZone;

      let firstDayOfWeek = 1;
      // Some browsers implement this as an accessor property, some as a method. FF has no support atm, so it will default to Monday.
      if (locale.weekInfo) {
        firstDayOfWeek = locale.weekInfo.firstDay % 7; // map from 1-7 for 7=Sunday, to 0-6 for 0=Sunday
      } else if (locale.getWeekInfo) {
        firstDayOfWeek = locale.getWeekInfo().firstDay % 7;
      }

      // Update userInfo based on Auth0 user data if available
      const updatedUserInfo: UserProvider = {
        email: user.email || '',
        locale: language,
        firstDayOfWeek: firstDayOfWeek as DayOfWeek,
        primaryCurrency,
        tz,
      };

      setUserInfo(updatedUserInfo);
      void i18next.changeLanguage(updatedUserInfo.locale);
    }
  }, [isAuthenticated, user]);

  return (
    <UserContext.Provider value={userInfo}>{children}</UserContext.Provider>
  );
}

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used inside the UserContextProvider');
  }

  return context;
};
