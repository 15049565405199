import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from 'react';

const _noop = () => {
  // noop
};

export const InactivityGuardContext = createContext<{
  initiate: ({
    onLogout,
    onWarning,
  }: {
    onLogout: () => void;
    onWarning: () => void;
  }) => void;
  blockUpdate: () => void;
  onUserAction: () => void;
}>({
  initiate: _noop,
  blockUpdate: _noop,
  onUserAction: _noop,
});

const WARNING_TRIGGER_IN_MS = 30 * 1000;

export function InactivityGuardProvider({ children }: PropsWithChildren) {
  const interval = useRef<number | null>(null);
  const batch = useRef<boolean>(false);
  const logoutCb = useRef<() => void>();
  const warningCb = useRef<() => void>();
  const showingDialog = useRef<boolean>();

  function updateExpiryTime(force = false) {
    if ((batch.current || showingDialog.current) && !force) {
      return;
    }

    batch.current = true;
    const expireTime = Date.now() + 5 * 60 * 1000;
    try {
      localStorage.setItem('sessionExpire', expireTime.toString());
    } catch (e) {
      console.error('Cannot save session expire time');
    }

    setTimeout(() => {
      batch.current = false;
    }, 500);
  }

  async function checkForInactivity() {
    const expireTimeString = localStorage.getItem('sessionExpire');
    const expireTime = expireTimeString ? parseInt(expireTimeString) : 0;

    if (expireTime < Date.now()) {
      if (!interval.current) {
        return;
      }

      interval.current = null;
      if (logoutCb.current) {
        logoutCb.current();
      }
    } else if (expireTime < Date.now() + WARNING_TRIGGER_IN_MS) {
      if (warningCb.current) {
        warningCb.current();
      }
    }
  }

  function userActionHandler() {
    updateExpiryTime();
  }

  useEffect(() => {
    return () => {
      window.removeEventListener('click', userActionHandler);
      window.removeEventListener('keydown', userActionHandler);
      window.removeEventListener('wheel', userActionHandler);
      window.removeEventListener('touchmove', userActionHandler);
    };
  }, []);

  function initiate({
    onLogout,
    onWarning,
  }: {
    onLogout: () => void;
    onWarning: () => void;
  }) {
    logoutCb.current = onLogout;
    warningCb.current = onWarning;

    updateExpiryTime();
    interval.current = window.setInterval(() => {
      checkForInactivity();
    }, 1000);

    window.addEventListener('click', userActionHandler);
    window.addEventListener('keydown', userActionHandler);
    window.addEventListener('wheel', userActionHandler);
    window.addEventListener('touchmove', userActionHandler);
  }

  function blockUpdate() {
    showingDialog.current = true;
  }

  function onUserAction() {
    updateExpiryTime(true);
    showingDialog.current = false;
  }

  return (
    <InactivityGuardContext.Provider
      value={{
        initiate,
        blockUpdate,
        onUserAction,
      }}
    >
      {children}
    </InactivityGuardContext.Provider>
  );
}

export const useInactivityGuard = () => {
  const context = useContext(InactivityGuardContext);

  if (!context) {
    throw new Error(
      'useInactivityGuard must be used inside the InactivityGuardProvider',
    );
  }

  return context;
};
