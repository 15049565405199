import { createRoute } from '@tanstack/react-router';
import { indexRoute } from '@agyt/client/web/feature/common';
import AccountDetailsPage from './account-details-page';
import { SupportedCurrency } from '@agyt/shared/util/common';

export const accountDetailsRoot = createRoute({
  getParentRoute: () => indexRoute,
  path: '/account-details',
  validateSearch: (
    search: Record<string, unknown>,
  ): { currency?: SupportedCurrency; returnTo?: string } =>
    search.currency && search.returnTo
      ? {
          currency: search.currency as SupportedCurrency,
          returnTo: String(search.returnTo),
        }
      : {},
});

const accountDetailsIndexRoute = createRoute({
  getParentRoute: () => accountDetailsRoot,
  path: '/',
  component: AccountDetailsPage,
});

export const accountDetailsRoutes = accountDetailsRoot.addChildren([
  accountDetailsIndexRoute,
]);
