import { createRoute } from '@tanstack/react-router';
import { indexRoute } from '@agyt/client/web/feature/common';
import AccountCurrenciesPage from './account-currencies-page';
import AddAccountCurrencyPage from './add-account-currency-page';

const accountCurrenciesRoot = createRoute({
  getParentRoute: () => indexRoute,
  path: '/account-currencies',
});

const accountCurrenciesIndexRoute = createRoute({
  getParentRoute: () => accountCurrenciesRoot,
  path: '/',
  component: AccountCurrenciesPage,
});

const addAccountCurencyRoute = createRoute({
  getParentRoute: () => accountCurrenciesRoot,
  path: '/new',
  component: AddAccountCurrencyPage,
});

export const accountCurrenciesRoutes = accountCurrenciesRoot.addChildren([
  accountCurrenciesIndexRoute,
  addAccountCurencyRoute,
]);
